import { ActionType } from "../model";

export const getTransferOrdersList = (data) => async (dispatch, getState, api) => {
    try {
        const resp = await api.get('TransferOrdersAdmin/GetTransferOrdersList', {}, getState().auth.authToken);
        return dispatch({ type: ActionType.GETTRANSFERORDERSLIST, payload: resp });
    } catch (error) {
        const res = [{'status': 404, 'error': 'Error in GetTransferOrdersList'}];
        return dispatch({ type: ActionType.GETTRANSFERORDERSLIST, payload: res });
    }
};
export const getTransferOrderLineList = (id) => async (dispatch, getState, api) => {
	try {
		const resp = await api.get('TransferOrdersAdmin/GetTransferOrderLineList/'+id, {}, getState().auth.authToken);
	  return dispatch({ type: ActionType.GETTRANSFERORDERLINELIST, payload: resp });
	} catch (error) {
		const res = [{'status': 404, 'error': 'Error in getTransferOrderLineList'}];
	  return dispatch({ type: ActionType.GETTRANSFERORDERLINELIST, payload: res });
	}
}
export const deleteTransferOrder = (id) => async (dispatch, getState, api) => {
    try {        
        const resp = await api.delete('TransferOrdersAdmin/DeleteTransferOrder/'+ id, {}, '', getState().auth.authToken);
        return dispatch({ type: ActionType.DELETETRANSFERORDER, payload: resp });
    } catch (error) {
		const res = [{'status': 404, 'error': 'Error in DeleteAttributeValues'}];
        return dispatch({ type: ActionType.DELETETRANSFERORDER, payload: res });
    }
};
export const updateTransferOrderLines = (orderLines) => async (dispatch, getState, api) => {
    try {        
        const resp = await api.put('TransferOrdersAdmin/UpdateTransferOrderLines', orderLines, '', getState().auth.authToken);
        return dispatch({ type: ActionType.UPDATEATTRIBUTEVALUE, payload: resp });
    } catch (error) {
    	const res = [{'status': 404, 'error': 'Error in UpdateTransferOrderLines'}];
        return dispatch({ type: ActionType.UPDATEATTRIBUTEVALUE, payload: res });
    }
};  
export const updateTransferOrderToSent = (id) => async (dispatch, getState, api) => {
    try {        
        const resp = await api.put('TransferOrdersAdmin/UpdateTransferOrderToSent/'+ id, {}, '', getState().auth.authToken);
        return dispatch({ type: ActionType.UPDATETRANSFERORDERTOSEND, payload: resp });
    } catch (error) {
		const res = [{'status': 404, 'error': 'Error in updateTransferOrderToSent'}];
        return dispatch({ type: ActionType.UPDATETRANSFERORDERTOSEND, payload: res });
    }
};
export const updateTransferOrderToReceived = (id) => async (dispatch, getState, api) => {
    try {        
        const resp = await api.put('TransferOrdersAdmin/UpdateTransferOrderToReceived/'+ id, {}, '', getState().auth.authToken);
        return dispatch({ type: ActionType.UPDATETRANSFERORDERTOSEND, payload: resp });
    } catch (error) {
		const res = [{'status': 404, 'error': 'Error in UpdateTransferOrderToReceived'}];
        return dispatch({ type: ActionType.UPDATETRANSFERORDERTOSEND, payload: res });
    }
};

