import { ActionType } from "../model";


export const SignIn = (data) => async (dispatch, getState, api) => {
	try {
		let resp = await api.post('Authentication/Login', data);
		if(resp.success == 'false') {
			let res = [{'status': 403, 'error': 'Error in getting banner list'}];
			return dispatch({ type: ActionType.SIGNIN, payload: res });
		} else {
			return dispatch({ type: ActionType.SIGNIN, payload: resp.data });
		}
	} catch (error) {
		let res = [{'status': 403, 'error': 'Error in getting banner list'}];
		return dispatch({ type: ActionType.SIGNIN, payload: res });
	}
};

export const ValidateToken = (data) => async (dispatch, getState, api) => {
	try {
		let resp = await api.post('Authentication/ValidateToken', data);
		if(resp.success == 'false') {
			let res = [{'status': 403, 'error': 'Error in ValidateToken'}];
			return dispatch({ type: ActionType.VALIDATETOKEN, payload: res });
		} else {
			return dispatch({ type: ActionType.VALIDATETOKEN, payload: resp.data });
		}
	} catch (error) {
		let res = [{'status': 403, 'error': 'Error in ValidateToken'}];
		return dispatch({ type: ActionType.VALIDATETOKEN, payload: res });
	}
};

export const SignOut = () => async (dispatch, getState, api) => {
	return dispatch({ type: ActionType.SIGNOUT, payload: {} });
};
