import { ActionType } from "../model";


export const submitImage = (data) => async (dispatch, getState, api) => {
    try {
        let resp = await api.post('Common/UploadFileToServer', data, '', getState().auth.authToken);
        return dispatch({ type: ActionType.UPLOADFILETOSERVER, payload: resp });
    } catch (error) {
    let res = [{'status': 404, 'error': 'Error in uploading file to server'}];
        return dispatch({ type: ActionType.UPLOADFILETOSERVER, payload: res });
    }
};
