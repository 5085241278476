import { ActionType } from "../model";

export const getHelpSectionList = () => async (dispatch, getState, api) => {
  	try {
  		let resp = await api.get('HelpPageAdmin/GetHelpPageSections', {}, getState().auth.authToken);
  		return dispatch({ type: ActionType.GETHELPPAGESECTIONS, payload: resp });
  	} catch (error) {
      let res = [{'status': 404, 'error': 'Error in getting help section list'}];
  		return dispatch({ type: ActionType.GETHELPPAGESECTIONS, payload: res });
  	}
};

export const getHelpSectionQAList = (recordId) => async (dispatch, getState, api) => {
  try {
    let resp = await api.get('HelpPageAdmin/GetHelpPageQAbySection/'+recordId, {}, getState().auth.authToken);
    return dispatch({ type: ActionType.GETHELPPAGEQABYSECTION, payload: resp });
  } catch (error) {
    let res = [{'status': 404, 'error': 'Error in getting section QA list'}];
    return dispatch({ type: ActionType.GETHELPPAGEQABYSECTION, payload: res });
  }
}

export const addHelpSection = (data) => async (dispatch, getState, api) => {
    try {
        let resp = await api.post('HelpPageAdmin/AddSection', data, {}, getState().auth.authToken);
        return dispatch({ type: ActionType.ADDSECTION, payload: resp });
    } catch (error) {
    let res = [{'status': 404, 'error': 'Error in adding help section'}];
        return dispatch({ type: ActionType.ADDSECTION, payload: res });
    }
};

export const deleteHelpSection = (data) => async (dispatch, getState, api) => {
    try {
        let resp = await api.delete('HelpPageAdmin/DeleteSection/'+data, {}, getState().auth.authToken);
        return dispatch({ type: ActionType.DELETEHELPSECTION, payload: resp });
    } catch (error) {
    let res = [{'status': 404, 'error': 'Error in getting deleting section'}];
        return dispatch({ type: ActionType.DELETEHELPSECTION, payload: res });
    }
};

export const updateHelpSection = (data) => async (dispatch, getState, api) => {
    try {
        let resp = await api.post('HelpPageAdmin/UpdateSection', data, {}, getState().auth.authToken);
        return dispatch({ type: ActionType.UPDATESECTION, payload: resp });
    } catch (error) {
    let res = [{'status': 404, 'error': 'Error in updating section'}];
        return dispatch({ type: ActionType.UPDATESECTION, payload: res });
    }
};

export const deleteQA = (data) => async (dispatch, getState, api) => {
    try {
        let resp = await api.delete('HelpPageAdmin/DeleteQA/'+data, {}, getState().auth.authToken);
        return dispatch({ type: ActionType.DELETEQA, payload: resp });
    } catch (error) {
    let res = [{'status': 404, 'error': 'Error in deleting QA'}];
        return dispatch({ type: ActionType.DELETEQA, payload: res });
    }
};

export const updateQA = (data) => async (dispatch, getState, api) => {
    try {
        let resp = await api.post('HelpPageAdmin/UpdateQA', data, {}, getState().auth.authToken);
          return dispatch({ type: ActionType.UPDATEQA, payload: resp });
    } catch (error) {
    let res = [{'status': 404, 'error': 'Error in updating QA'}];
        return dispatch({ type: ActionType.UPDATEQA, payload: res });
    }
};

export const addHelpSectionQA = (data) => async (dispatch, getState, api) => {
    try {
        let resp = await api.post('HelpPageAdmin/AddQA', data, {}, getState().auth.authToken);
        return dispatch({ type: ActionType.ADDQA, payload: resp });
    } catch (error) {
    let res = [{'status': 404, 'error': 'Error in adding QA'}];
        return dispatch({ type: ActionType.ADDQA, payload: res });
    }
};
