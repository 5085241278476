import { ActionType } from "../model";

export const getCouponList = () => async (dispatch, getState, api) => {
  	try {
  		let resp = await api.get('CouponsAdmin/GetCouponsList', {}, getState().auth.authToken);
  		return dispatch({ type: ActionType.GETCOUPON, payload: resp });
  	} catch (error) {
      let res = [{'status': 404, 'error': 'Error in getting banner list'}];
  		return dispatch({ type: ActionType.GETCOUPON, payload: res });
  	}
};

export const getCouponTypeList = () => async (dispatch, getState, api) => {
    try {
        let resp = await api.get('CouponsAdmin/GetCouponsDiscountTypesList', {}, getState().auth.authToken);
        return dispatch({ type: ActionType.GETCOUPONTYPES, payload: resp });
    } catch (error) {
    let res = [{'status': 404, 'error': 'Error in getting getCouponTypeList list'}];
        return dispatch({ type: ActionType.GETCOUPONTYPES, payload: res });
    }
};


export const addCouponList = (data) => async (dispatch, getState, api) => {
    try {
        let resp = await api.post('CouponsAdmin/AddCoupon', data, {}, getState().auth.authToken);
        return dispatch({ type: ActionType.ADDCOUPON, payload: resp });
    } catch (error) {
    let res = [{'status': 404, 'error': 'Error in getting banner list'}];
        return dispatch({ type: ActionType.ADDCOUPON, payload: res });
    }
};

export const deleteCoupon = (data) => async (dispatch, getState, api) => {
    try {
        let resp = await api.delete('CouponsAdmin/DeleteCoupon/'+data, {}, {}, getState().auth.authToken);
        return dispatch({ type: ActionType.DELETECOUPON, payload: resp });
    } catch (error) {
    let res = [{'status': 404, 'error': 'Error in getting banner list'}];
        return dispatch({ type: ActionType.DELETECOUPON, payload: res });
    }
};

export const updateCoupon = (data) => async (dispatch, getState, api) => {
    try {
        let resp = await api.put('CouponsAdmin/UpdateCoupon', data, {}, getState().auth.authToken);
        return dispatch({ type: ActionType.UPDATECOUPON, payload: resp });
    } catch (error) {
    let res = [{'status': 404, 'error': 'Error in updating coupons'}];
        return dispatch({ type: ActionType.UPDATECOUPON, payload: res });
    }
};
