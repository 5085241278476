

  export  class ApiService {

    getQueryString(params) {
        if (!params) return null;
        const esc = encodeURIComponent;
        return Object.keys(params)
            .map(k => esc(k) + '=' + esc(params[k]))
            .join('&');
    }

    async executeRequest(params) {
      return new Promise((resolve, reject) => {
        let fetchUrl = params.url;
        const method = params.method || 'GET';
        let body;
        let headers = new Headers({
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        });
        
        if ( JSON.stringify(params.queryParameters) && 
             JSON.stringify(params.queryParameters) !== undefined && 
             JSON.stringify(params.queryParameters) !== '{}' && 
             params.queryParameter !== '') {
                const paramsString =this.getQueryString(params.queryParameters) 
                if(paramsString)
                fetchUrl += '?' + paramsString;
        }

        if (params.body) {
          body = JSON.stringify(params.body);
        }

        if (params.accessToken) {
          headers.append('Authorization', 'Bearer ' + params.accessToken);
        }

        const myRequest = { method, body, headers };

        fetch(fetchUrl, myRequest)
        .then((result) => {
            resolve(result.json());
        }).catch((error) => {
          reject(error);
        });
      });
    }
  }

  export class Api extends ApiService {
    constructor(url) {
      super()
      this.baseApiUrl= url;
    }

    
    async get(url, query, accessToken) {

      const requestParams = {
        method: 'GET',
        url: `${this.baseApiUrl}/${url}`,
        queryParameters: query,
        accessToken: accessToken
      };
      return this.executeRequest(requestParams);
    }

     async post(url, data, query, accessToken) {
      const requestParams= {
        method: 'POST',
        url: `${this.baseApiUrl}/${url}`,
        body: data,
        queryParameters: query,
        accessToken: accessToken
      };
      return this.executeRequest(requestParams);
    }

     async put(url, data, query, accessToken) {
      const requestParams = {
        method: 'PUT',
        url: `${this.baseApiUrl}/${url}`,
        body: data,
        queryParameters: query,
        accessToken: accessToken
      };
      return this.executeRequest(requestParams);
    }
     async patch(url, data, query, accessToken) {
      const requestParams = {
        method: 'PATCH',
        url: `${this.baseApiUrl}/${url}`,
        body: data,
        queryParameters: query,
        accessToken: accessToken
      };
      return this.executeRequest(requestParams);
    }
     async delete(url, data, query, accessToken) {
      const requestParams = {
        method: 'DELETE',
        url: `${this.baseApiUrl}/${url}`,
        body: data,
        queryParameters: query,
        accessToken: accessToken
      };
      return this.executeRequest(requestParams);
    }
  }
