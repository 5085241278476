import { ActionType } from "../model";

export const addShop = (data) => async (dispatch, getState, api) => {
    try {
        let resp = await api.post('ShopsAdmin/AddShops', data, {}, getState().auth.authToken);
        return dispatch({ type: ActionType.ADDSHOPS, payload: resp });
    } catch (error) {
    let res = [{'status': 404, 'error': 'Error in getting banner list'}];
        return dispatch({ type: ActionType.ADDSHOPS, payload: res });
    }
};

export const deleteShop = (data) => async (dispatch, getState, api) => {
    try {
        let resp = await api.delete('ShopsAdmin/DeleteShop/'+data, {}, {}, getState().auth.authToken);
        return dispatch({ type: ActionType.DELETESHOP, payload: resp });
    } catch (error) {
    let res = [{'status': 404, 'error': 'Error in deleting shop'}];
        return dispatch({ type: ActionType.DELETESHOP, payload: res });
    }
};

export const updateShop = (data) => async (dispatch, getState, api) => {
    try {
        let resp = await api.post('ShopsAdmin/UpdateShops/'+data.id, data, {}, getState().auth.authToken);
        return dispatch({ type: ActionType.SHOPID, payload: resp });
    } catch (error) {
    let res = [{'status': 404, 'error': 'Error in updating shops'}];
        return dispatch({ type: ActionType.SHOPID, payload: res });
    }
};

export const getShopList = () => async (dispatch, getState, api) => {
  	try {
  		let resp = await api.get('ShopsAdmin/GetShopsList', {}, getState().auth.authToken);
  		return dispatch({ type: ActionType.GETSHOP, payload: resp });
  	} catch (error) {
      let res = [{'status': 404, 'error': 'Error in getting banner list'}];
  		return dispatch({ type: ActionType.GETSHOP, payload: res });
  	}
};
export const getAlCountries = () => async (dispatch, getState, api) => {
  try {
    let resp = await api.get('Common/GetAllCountries', {}, getState().auth.authToken);
    return dispatch({ type: ActionType.ALLCOUNTRIES, payload: resp });
  } catch (error) {
    let res = [{'status': 404, 'error': 'Error in country list'}];
    return dispatch({ type: ActionType.ALLCOUNTRIES, payload: res });
  }
}
export const getShop = (id) => async (dispatch, getState, api) => {
  try {
    let resp = await api.get('ShopsAdmin/GetShopDetail/'+id, {}, getState().auth.authToken);
    return dispatch({ type: ActionType.GETWEBPAGE, payload: resp });
  } catch (error) {
    let res = [{'status': 404, 'error': 'Error in getting shop'}];
    return dispatch({ type: ActionType.GETWEBPAGE, payload: res });
  }
}
