export const ActionType = {
    SIGNIN:"SIGNIN",
    SIGNOUT:"SIGNOUT",
    VALIDATETOKEN:"VALIDATETOKEN",
    GETBANNER:"GETBANNER",
    GETPOSITIONS:"GETPOSITIONS",
    ADDBANNER:"ADDBANNER",
    GETBANNERDETAILS:"GETBANNERDETAILS",
    UPDATEBANNER:"UPDATEBANNER",
    DELETEBANNER:"DELETEBANNER",
    SHOPID:"SHOPID",
    GETSHOPSLIST:"GETSHOPSLIST",
    ADDPREVIEWTOKEN:"ADDPREVIEWTOKEN",
    GETPREVIEWTOKENS:"GETPREVIEWTOKENS",
    UPDATEPREVIEWTOKEN:"UPDATEPREVIEWTOKEN",
    ADDPREVIEWTOKENTOBANNERS:"ADDPREVIEWTOKENTOBANNERS",
    GETCONTENTLIST:"GETCONTENTLIST",
    ADDCONTENTLIST:"ADDCONTENTLIST",
    UPLOADFILETOSERVER:"UPLOADFILETOSERVER",
    DELETECONTENT:"DELETECONTENT",
    GETCONTENTBYID:"GETCONTENTBYID",
    UPDATECONTENTID:"UPDATECONTENTID",
    GETCONTENTTYPES:"GETCONTENTTYPES",
    GETPRODUCTSEDITOR:"GETPRODUCTSEDITOR",
    GETATTRIBUTESVALUESLIST:"GETATTRIBUTESVALUESLIST",
    GETPRODUCTATTRIBUTESVALUESSELECTED:"GETPRODUCTATTRIBUTESVALUESSELECTED",
    UPDATEPRODUCTSATTRIBUTESVALUES:"UPDATEPRODUCTSATTRIBUTESVALUES",
    GETATTRIBUTESLIST:"GETATTRIBUTESLIST",
    GETATTRIBUTEVALUES:"GETATTRIBUTEVALUES",
    GETATTRIBUTEDETAIL:"GETATTRIBUTEDETAIL",
    GETATTRIBUTETYPES:"GETATTRIBUTETYPES",
    GETATTRIBUTEVALUEDETAIL:"GETATTRIBUTEVALUEDETAIL",
    UPDATEATTRIBUTEVALUE:"UPDATEATTRIBUTEVALUE",
    INSERTATTRIBUTEVALUE:"INSERTATTRIBUTEVALUE",
    DELETEATTRIBUTEVALUE:"DELETEATTRIBUTEVALUE",
    INSERTATTRIBUTE:"INSERTATTRIBUTE",
    UPDATEATTRIBUTE:"UPDATEATTRIBUTE",
    DELETEATTRIBUTE:"DELETEATTRIBUTE",
    PUBLISHINGLINESLIST:"PUBLISHINGLINESLIST",    
    PUBLISHINGLINESLISTBYSHOP:"PUBLISHINGLINESLISTBYSHOP",    
    GETCATEGORIESBYPUBLISHINGLINE:"GETCATEGORIESBYPUBLISHINGLINE",
    GETCOLLECTIONSBYCATEGORY:"GETCOLLECTIONSBYCATEGORY",
    GETPUBLISHINGLINE:"GETPUBLISHINGLINE",
    ADDPUBLISHINGLINE:"ADDPUBLISHINGLINE",
    UPDATEPUBLISHINGLINE:"UPDATEPUBLISHINGLINE",
    GETCATEGORY:"GETCATEGORY",
    ADDCATEGORY:"ADDCATEGORY",
    UPDATECATEGORY:"UPDATECATEGORY",
    DELETEPUBLISHINGLINE:"DELETEPUBLISHINGLINE",
    DELETECATEGORY:"DELETECATEGORY",
    DELETECOLLECTION:"DELETECOLLECTION",
    ADDEVENT:"ADDEVENT",
    GETEVENT:"GETEVENT",
    DELETEEVENT:"DELETEEVENT",
    EVENTDETAILS:"EVENTDETAILS",
    UPDATEEVENT:"UPDATEEVENT",
    SEARCHCONTENT:"SEARCHCONTENT",
    GETALLSHOPLIST:"GETALLSHOPLIST",
    GETCOUPON:"GETCOUPON",
    GETCOUPONTYPES:"GETCOUPONTYPES",
    ADDCOUPON:"ADDCOUPON",
    DELETECOUPON:"DELETECOUPON",
    ALLPROVINCES:"ALLPROVINCES",
    EVENTTYPELIST:"EVENTTYPELIST",
    SEARCHCONTENTLIST:"SEARCHCONTENTLIST",
    ADDPRODUCTCONTENTLIST:"ADDPRODUCTCONTENTLIST",
    GETCONTENTPRODUCTLIST:"GETCONTENTPRODUCTLIST",
    UPDATECOUPON:"UPDATECOUPON",
    GETACCESSTOKEN:"GETACCESSTOKEN",
    EDITIONSPRODUCTLIST:"EDITIONSPRODUCTLIST",
    RELATEDPRODUCTLIST:"RELATEDPRODUCTLIST",
    ADDSHOPS:"ADDSHOPS",
    DELETESHOP:"DELETESHOP",
    UPDATESHOPS:"UPDATESHOPS",
    GETSHOP:"GETSHOP",
    ALLCOUNTRIES:"ALLCOUNTRIES",
    GETDELIVERYTYPELIST:"GETDELIVERYTYPELIST",
    GETPRODUCTTYPELIST:"GETPRODUCTTYPELIST",
    GETPRODUCTCOUNTRYTAXESLIST:"GETPRODUCTCOUNTRYTAXESLIST",
    ADDPRODUCTCOUNTRYTAXES:"ADDPRODUCTCOUNTRYTAXES",
    DELETEPRODUCTCOUNTRYTAX:"DELETEPRODUCTCOUNTRYTAX",
    UPDATEPRODUCTCOUNTRYTAXES:"UPDATEPRODUCTCOUNTRYTAXES",
    GETSHIPPINGCOMPANYLIST:"GETSHIPPINGCOMPANYLIST",
    GETSHIPPINGCOMPANYDETAILS:"GETSHIPPINGCOMPANYDETAILS",
    DELETESHIPPINGCOMPANY:"DELETESHIPPINGCOMPANY",
    UPDATESHIPPINGCOMPANY:"UPDATESHIPPINGCOMPANY",
    ADDSHIPPINGCOMPANY:"ADDSHIPPINGCOMPANY",
    ADDSHIPPINGCOUNTRY:"ADDSHIPPINGCOUNTRY",
    UPDATESHIPPINGCOUNTRY:"UPDATESHIPPINGCOUNTRY",
    GETSHIPPINGCOUNTRYLIST:"GETSHIPPINGCOUNTRYLIST",
    DELETESHIPPINGCOUNTRY:"DELETESHIPPINGCOUNTRY",
    GETSHIPPINGCOMPANYPROVINCESLIST:"GETSHIPPINGCOMPANYPROVINCESLIST",
    UPDATESHIPPINGCOMPANYPROVINCES:"UPDATESHIPPINGCOMPANYPROVINCES",
    DELETESHIPPINGCOMPANYPROVINCES:"DELETESHIPPINGCOMPANYPROVINCES",
    ADDSHIPPINGCOMPANYPROVINCES:"ADDSHIPPINGCOMPANYPROVINCES",
    GETWEBTEXTLIST:"GETWEBTEXTLIST",
    ADDWEBTEXTS:"ADDWEBTEXTS",
    UPDATEWEBTEXTS:"UPDATEWEBTEXTS",
    GETWEBTEXTDETAILS:"GETWEBTEXTDETAILS",
    GETMENULIST:"GETMENULIST",
    UPDATEMENU:"UPDATEMENU",
    ADDMENU:"ADDMENU",
    DELETEMENU:"DELETEMENU",
    GETPRODUCTEDITIONS:"GETPRODUCTEDITIONS",
    GETPRODUCTSRELATED:"GETPRODUCTSRELATED",
    GETWEBPAGELIST:"GETWEBPAGELIST",
    ADDWEBPAGE:"ADDWEBPAGE",
    UPDATEWEBPAGE:"UPDATEWEBPAGE",
    GETWEBPAGE:"GETWEBPAGE",
    DELETEWEBPAGE:"DELETEWEBPAGE",
    GETHELPPAGESECTIONS:"GETHELPPAGESECTIONS",
    GETHELPPAGEQABYSECTION:"GETHELPPAGEQABYSECTION",
    UPDATESECTION:"UPDATESECTION",
    ADDSECTION:"ADDSECTION",
    DELETEHELPSECTION:"DELETEHELPSECTION",
    UPDATEQA:"UPDATEQA",
    DELETEQA:"DELETEQA",
    ADDQA:"ADDQA",
    GETEDITIONDATESFROMSUBSCRIPTION:"GETEDITIONDATESFROMSUBSCRIPTION",
    GETSUBSCRIPTIONORDERSBYDATE:"GETSUBSCRIPTIONORDERSBYDATE",        
    TRYSUBSCRIPTIONORDERPAYMENT:"TRYSUBSCRIPTIONORDERPAYMENT"    ,
    GETPOSTRANSACTIONLOG:"GETPOSTRANSACTIONLOG",
    SENDEMAILRESUBSCRIBE:"SENDEMAILRESUBSCRIBE",
    ADDSHOPSTOPUBLISHINGLINE:"ADDSHOPSTOPUBLISHINGLINE",  
    GETPRODUCTCATEGORIESBYSHOP:"GETPRODUCTCATEGORIESBYSHOP",
    UPDTEPRODUCTCATEGORIES : "UPDTEPRODUCTCATEGORIES",
    DELETEPRODUCTCATEGORIES : "DELETEPRODUCTCATEGORIES",
    GETPRODUCTTEXTS:"GETPRODUCTTEXTS",
    UPDATEPRODUCTTEXTS:"UPDATEPRODUCTTEXTS",
    GETPRODUCTMEDIAREVIEWS:"GETPRODUCTMEDIAREVIEWS",
    UPDATEPRODUCTMEDIAREVIEW:"UPDATEPRODUCTMEDIAREVIEW",
    ADDPRODUCTMEDIAREVIEW:"ADDPRODUCTMEDIAREVIEW",
    DELETEPRODUCTMEDIAREVIEW:"DELETEPRODUCTMEDIAREVIEW",
    GETPRODUCTPRESSMATERIAL:"GETPRODUCTPRESSMATERIAL",
    UPDATEPRODUCTPRESSMATERIAL:"UPDATEPRODUCTPRESSMATERIAL",
    DELETEPRODUCTPRESSMATERIAL:"DELETEPRODUCTPRESSMATERIAL",
    ADDPRODUCTPRESSMATERIAL:"ADDPRODUCTPRESSMATERIAL",
    GETPRODUCTAUTHORS:"GETPRODUCTAUTHORS",
    ADDEVENTPRODUCT:"ADDEVENTPRODUCT",
    DELETEEVENTPRODUCT:"DELETEEVENTPRODUCT",
	GETPRODUTCSPENDINGTOSERVEWITHOUTSTOCK:"GETPRODUTCSPENDINGTOSERVEWITHOUTSTOCK",
	GETSTORAGELIST:"GETSTORAGELIST",
	CREATETRANSFERORDER:"CREATETRANSFERORDER",
	GETTRANSFERORDERSLIST:"GETTRANSFERORDERSLIST",
	GETTRANSFERORDERLINELIST:"GETTRANSFERORDERLINELIST",
	DELETETRANSFERORDER:"DELETETRANSFERORDER",
	UPDTETRANSFERORDERLINES:"UPDTETRANSFERORDERLINES",
	UPDATETRANSFERORDERTOSEND:"UPDATETRANSFERORDERTOSEND",
	UPDATETRANSFERORDERTORECEIVED:"UPDATETRANSFERORDERTORECEIVED"
}
