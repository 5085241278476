import { ActionType } from "../model";

export const getPublisingLinesList = () => async (dispatch, getState, api) => {
    try {
        let resp = await api.get('PublishingLinesAdmin/GetPublishingLinesList', {}, getState().auth.authToken);
        return dispatch({ type: ActionType.PUBLISHINGLINESLIST, payload: resp });
    } catch (error) {
    let res = [{'status': 404, 'error': 'Error in getPublisingLinesList'}];
        return dispatch({ type: ActionType.PUBLISHINGLINESLIST, payload: res });
    }
};
export const getPublisingLinesListByShop = (id) => async (dispatch, getState, api) => {
    try {
        let resp = await api.get('PublishingLinesAdmin/GetPublishingLinesListByShop/'+ id, {}, getState().auth.authToken);
        return dispatch({ type: ActionType.PUBLISHINGLINESLISTBYSHOP, payload: resp });
    } catch (error) {
    let res = [{'status': 404, 'error': 'Error in getPublisingLinesListByShop'}];
        return dispatch({ type: ActionType.PUBLISHINGLINESLISTBYSHOP, payload: res });
    }
};
export const getPublishingLine = (id) => async (dispatch, getState, api) => {
    try {
        let resp = await api.get('PublishingLinesAdmin/GetPublishingLine/'+ id, {}, getState().auth.authToken);
        return dispatch({ type: ActionType.GETPUBLISHINGLINE, payload: resp });
    } catch (error) {
    let res = [{'status': 404, 'error': 'Error in getPublishingLine'}];
        return dispatch({ type: ActionType.GETPUBLISHINGLINE, payload: res });
    }
};
export const addPublishingLine = (pLine) => async (dispatch, getState, api) => {
    try {
        let resp = await api.post('PublishingLinesAdmin/AddPublishingLine', pLine, getState().auth.authToken);
        return dispatch({ type: ActionType.ADDPUBLISHINGLINE, payload: resp });
    } catch (error) {
    let res = [{'status': 404, 'error': 'Error in addPublishingLine'}];
        return dispatch({ type: ActionType.ADDPUBLISHINGLINE, payload: res });
    }
};
export const updatePublishingLine = (pLine) => async (dispatch, getState, api) => {
    try {
        let resp = await api.put('PublishingLinesAdmin/UpdatePublishingLine', pLine, getState().auth.authToken);
        return dispatch({ type: ActionType.UPDATEPUBLISHINGLINE, payload: resp });
    } catch (error) {
    let res = [{'status': 404, 'error': 'Error in updatePublishingLine'}];
        return dispatch({ type: ActionType.UPDATEPUBLISHINGLINE, payload: res });
    }
};
export const deletePublishingLine = (id) => async (dispatch, getState, api) => {
    try {        
        let resp = await api.delete('PublishingLinesAdmin/DeletePublishingLine/'+ id,  {}, '', getState().auth.authToken);        
        return dispatch({ type: ActionType.DELETEPUBLISHINGLINE, payload: resp });
    } catch (error) {        
        let res = [{'status': 404, 'error': 'Error in deletePublishingLine'}];
        return dispatch({ type: ActionType.DELETEPUBLISHINGLINE, payload: res });
    }
};
export const addShopsToPublishingLine = (shopsPublisingLine) => async (dispatch, getState, api) => {
    try {        
        let resp = await api.post('PublishingLinesAdmin/AddShopsToPublishingLine', shopsPublisingLine, '', getState().auth.authToken);
        return dispatch({ type: ActionType.ADDSHOPSTOPUBLISHINGLINE, payload: resp });
    } catch (error) {
    let res = [{'status': 404, 'error': 'Error in addShopsToPublishingLine'}];
        return dispatch({ type: ActionType.ADDSHOPSTOPUBLISHINGLINE, payload: res });
    }
};

