import { ActionType } from "../model";

export const getProductTypeList = () => async (dispatch, getState, api) => {
  	try {
  		let resp = await api.get('ProductType/GetProductTypeList', {}, getState().auth.authToken);
  		return dispatch({ type: ActionType.GETPRODUCTTYPELIST, payload: resp });
  	} catch (error) {
      let res = [{'status': 404, 'error': 'Error in getting product type list'}];
  		return dispatch({ type: ActionType.GETPRODUCTTYPELIST, payload: res });
  	}
};
export const getDeliveryTypeList = () => async (dispatch, getState, api) => {
  	try {
  		let resp = await api.get('DeliveryType/GetDeliveryTypeList', {}, getState().auth.authToken);
  		return dispatch({ type: ActionType.GETDELIVERYTYPELIST, payload: resp });
  	} catch (error) {
      let res = [{'status': 404, 'error': 'Error in getting delivery type list'}];
  		return dispatch({ type: ActionType.GETDELIVERYTYPELIST, payload: res });
  	}
};

export const getProductCountryTaxesList = () => async (dispatch, getState, api) => {
  	try {
  		let resp = await api.get('ProductCountryTaxes/GetProductCountryTaxesList', {}, getState().auth.authToken);
  		return dispatch({ type: ActionType.GETPRODUCTCOUNTRYTAXESLIST, payload: resp });
  	} catch (error) {
      let res = [{'status': 404, 'error': 'Error in getting delivery type list'}];
  		return dispatch({ type: ActionType.GETPRODUCTCOUNTRYTAXESLIST, payload: res });
  	}
};

export const addProductCountryTaxes = (data) => async (dispatch, getState, api) => {
    try {
        let resp = await api.post('ProductCountryTaxes/AddProductCountryTaxes', data, {}, getState().auth.authToken);
        return dispatch({ type: ActionType.ADDPRODUCTCOUNTRYTAXES, payload: resp });
    } catch (error) {
    let res = [{'status': 404, 'error': 'Error in getting banner list'}];
        return dispatch({ type: ActionType.ADDPRODUCTCOUNTRYTAXES, payload: res });
    }
};
export const deleteProductCountryTax = (data) => async (dispatch, getState, api) => {
    try {
        let resp = await api.delete('ProductCountryTaxes/DeleteProductCountryTax/'+data, {}, {}, getState().auth.authToken);
        return dispatch({ type: ActionType.DELETEPRODUCTCOUNTRYTAX, payload: resp });
    } catch (error) {
    let res = [{'status': 404, 'error': 'Error in deleting shop'}];
        return dispatch({ type: ActionType.DELETEPRODUCTCOUNTRYTAX, payload: res });
    }
};

export const updateProductCountryTaxes = (data) => async (dispatch, getState, api) => {
    try {
        let resp = await api.put('ProductCountryTaxes/UpdateProductCountryTaxes?Id='+data.id, data, {}, getState().auth.authToken);
        return dispatch({ type: ActionType.UPDATEPRODUCTCOUNTRYTAXES, payload: resp });
    } catch (error) {
    let res = [{'status': 404, 'error': 'Error in updating shops'}];
        return dispatch({ type: ActionType.UPDATEPRODUCTCOUNTRYTAXES, payload: res });
    }
};
