import { Action, ActionType } from "../../model";
import createReducer from "./createReducer";

export const banner = createReducer({}, {
	[ActionType.GETBANNER](state, action) {
		return action.payload;
	},
	[ActionType.SHOPID](state, action) {
		return action.payload;
	},

});
