import { ActionType } from "../model";

export const getCollectionsByCategory = (id) => async (dispatch, getState, api) => {
    try {
        let resp = await api.get('CollectionsAdmin/GetCollectionsbyCategory?categoryId='+ id, {}, getState().auth.authToken);
        return dispatch({ type: ActionType.GETCOLLECTIONSBYCATEGORY, payload: resp });
    } catch (error) {
    let res = [{'status': 404, 'error': 'Error in getCollectionsByCategory list'}];
        return dispatch({ type: ActionType.GETCOLLECTIONSBYCATEGORY, payload: res });
    }
};
export const getCollection = (id) => async (dispatch, getState, api) => {
    try {
        let resp = await api.get('CollectionsAdmin/GetCollection/'+ id, {}, getState().auth.authToken);
        return dispatch({ type: ActionType.GETCATEGORY, payload: resp });
    } catch (error) {
    let res = [{'status': 404, 'error': 'Error in getCollection list'}];
        return dispatch({ type: ActionType.GETCATEGORY, payload: res });
    }
};
export const addCollection = (collection) => async (dispatch, getState, api) => {
    try {
        let resp = await api.post('CollectionsAdmin/AddCollection', collection, getState().auth.authToken);
        return dispatch({ type: ActionType.ADDCATEGORY, payload: resp });
    } catch (error) {
    let res = [{'status': 404, 'error': 'Error in addCollection list'}];
        return dispatch({ type: ActionType.ADDCATEGORY, payload: res });
    }
};
export const updateCollection = (collection) => async (dispatch, getState, api) => {
    try {
        let resp = await api.put('CollectionsAdmin/UpdateCollection', collection, getState().auth.authToken);
        return dispatch({ type: ActionType.UPDATECATEGORY, payload: resp });
    } catch (error) {
    let res = [{'status': 404, 'error': 'Error in updateCollection list'}];
        return dispatch({ type: ActionType.UPDATECATEGORY, payload: res });
    }
};
export const deleteCollection = (id) => async (dispatch, getState, api) => {
    try {        
        let resp = await api.delete('CollectionsAdmin/DeleteCollection/'+ id,  {}, '', getState().auth.authToken);        
        return dispatch({ type: ActionType.DELETECOLLECTION, payload: resp });
    } catch (error) {        
        let res = [{'status': 404, 'error': 'Error in deleteCollection'}];
        return dispatch({ type: ActionType.DELETECOLLECTION, payload: res });
    }
};
