import React from 'react';
import ReactDOM from 'react-dom';
import '../src/scss/style.scss'
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import App from './App';
import configureStore from "./redux/store";


const { persistor, store } = configureStore();

const reduxRoot = (
  <Provider store={store}>
    <PersistGate
      loading={null}
      persistor={persistor}
    >
      <App />
    </PersistGate>
  </Provider>
);

if (process.env.NODE_ENV === 'production') {
  ReactDOM.render(
    reduxRoot,
    document.getElementById('root')
  );
} else {
  ReactDOM.render(
    <>
      {reduxRoot}
    </>,
    document.getElementById('root')
  );
}

//  If you want to start measuring performance in your app, pass a function
// to log results (f or example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
/*rebuild*/
/*rebuild*/
/*rebuild*/
/*rebuild*/
/*rebuild*/
/*rebuild*/
/*rebuild*/
/*rebuild*/
/*rebuild*/
//reportWebVitals() ;

