import { ActionType } from "../../model";
import createReducer from "./createReducer";

export const auth = createReducer({}, {
	[ActionType.SIGNIN](state, action) {
		return action.payload;
	},
	[ActionType.SIGNOUT](state, action) {
		return action.payload;
	},
	[ActionType.VALIDATETOKEN](state, action) {
		return action.payload;
	}	
});
