import { ActionType } from "../model";


export const getCategoriesByPublishingLine = (id) => async (dispatch, getState, api) => {
    try {
        let resp = await api.get('CategoriesAdmin/GetCategoriesByPublishingLine?publishingLineId='+ id, {}, getState().auth.authToken);
        return dispatch({ type: ActionType.GETCATEGORIESBYPUBLISHINGLINE, payload: resp });
    } catch (error) {
    let res = [{'status': 404, 'error': 'Error in getCategoriesByPublishingLine list'}];
        return dispatch({ type: ActionType.GETCATEGORIESBYPUBLISHINGLINE, payload: res });
    }
};
export const getCategory = (id) => async (dispatch, getState, api) => {
    try {
        let resp = await api.get('CategoriesAdmin/GetCategory/'+ id, {}, getState().auth.authToken);
        return dispatch({ type: ActionType.GETCATEGORY, payload: resp });
    } catch (error) {
    let res = [{'status': 404, 'error': 'Error in get Category'}];
        return dispatch({ type: ActionType.GETCATEGORY, payload: res });
    }
};
export const addCategory = (category) => async (dispatch, getState, api) => {
    try {
        let resp = await api.post('CategoriesAdmin/AddCategory', category, getState().auth.authToken);
        return dispatch({ type: ActionType.ADDCATEGORY, payload: resp });
    } catch (error) {
    let res = [{'status': 404, 'error': 'Error in addPublishingLine list'}];
        return dispatch({ type: ActionType.ADDCATEGORY, payload: res });
    }
};
export const updateCategory = (category) => async (dispatch, getState, api) => {
    try {
        let resp = await api.put('CategoriesAdmin/UpdateCategory', category, getState().auth.authToken);
        return dispatch({ type: ActionType.UPDATECATEGORY, payload: resp });
    } catch (error) {
    let res = [{'status': 404, 'error': 'Error in updatePublishingLine list'}];
        return dispatch({ type: ActionType.UPDATECATEGORY, payload: res });
    }
};
export const deleteCategory = (id) => async (dispatch, getState, api) => {
    try {        
        let resp = await api.delete('CategoriesAdmin/DeleteCategory/'+ id,  {}, '', getState().auth.authToken);        
        return dispatch({ type: ActionType.DELETECATEGORY, payload: resp });
    } catch (error) {        
        let res = [{'status': 404, 'error': 'Error in deleteCollection'}];
        return dispatch({ type: ActionType.DELETECATEGORY, payload: res });
    }
};




