
import { ActionType } from "../model";

export const getBannerList = () => async (dispatch, getState, api) => {
  	try {
  		let resp = await api.get('bannerAdmin/GetBannerList?PageNo=1&PageSize=10', {}, getState().auth.authToken);
  		return dispatch({ type: ActionType.GETBANNER, payload: resp });
  	} catch (error) {
      let res = [{'status': 404, 'error': 'Error in getting banner list'}];
  		return dispatch({ type: ActionType.GETBANNER, payload: res });
  	}
};

export const getBannerPositions = () => async (dispatch, getState, api) => {
  	try {
  		let resp = await api.get('bannerAdmin/GetPositions', {}, getState().auth.authToken);
  		return dispatch({ type: ActionType.GETPOSITIONS, payload: resp });
  	} catch (error) {
      let res = [{'status': 404, 'error': 'Error in getting banner list'}];
  		return dispatch({ type: ActionType.GETPOSITIONS, payload: res });
  	}
};


export const addBannerList = (data) => async (dispatch, getState, api) => {
  	try {
  		let resp = await api.post('bannerAdmin/AddBanner', data, '', getState().auth.authToken);
  		return dispatch({ type: ActionType.ADDBANNER, payload: resp });
  	} catch (error) {
      let res = [{'status': 404, 'error': 'Error in getting banner list'}];
  		return dispatch({ type: ActionType.ADDBANNER, payload: res });
  	}
};

export const updateBannerList = (data) => async (dispatch, getState, api) => {
  	try {
  		let resp = await api.put('bannerAdmin/UpdateBanner/'+data.id, data, '', getState().auth.authToken);
  		return dispatch({ type: ActionType.UPDATEBANNER, payload: resp });
  	} catch (error) {
      let res = [{'status': 404, 'error': 'Error in getting banner list'}];
  		return dispatch({ type: ActionType.UPDATEBANNER, payload: res });
  	}
};

export const getBannerDetailById = (recordId) => async (dispatch, getState, api) => {
  try {
    let resp = await api.get('bannerAdmin/GetBannerDetail/'+recordId, {}, getState().auth.authToken);
    return dispatch({ type: ActionType.GETBANNERDETAILS, payload: resp });
  } catch (error) {
    let res = [{'status': 404, 'error': 'Error in getting banner list'}];
    return dispatch({ type: ActionType.GETBANNERDETAILS, payload: res });
  }
}

export const deleteBannerById = (recordId) => async (dispatch, getState, api) => {
  try {
    let resp = await api.delete('bannerAdmin/DeleteBanner/'+recordId, {}, '', getState().auth.authToken);
    return dispatch({ type: ActionType.DELETEBANNER, payload: resp });
  } catch (error) {
    let res = [{'status': 404, 'error': 'Error in getting banner list'}];
    return dispatch({ type: ActionType.DELETEBANNER, payload: res });
  }
}

/*get all shops*/
export const getallShopId = () => async (dispatch, getState, api) => {
  try {
    let resp = await api.get('Shops/GetShopsList?pageNo=1&pageSize=999', {}, '', getState().auth.authToken);
    return dispatch({ type: ActionType.GETSHOPSLIST, payload: resp });
  } catch (error) {
    let res = [{'status': 404, 'error': 'Error in getting banner list'}];
    return dispatch({ type: ActionType.GETSHOPSLIST, payload: res });
  }
}

export const addPreviewToken = (data) => async (dispatch, getState, api) => {
  try {
    let resp = await api.post('bannerAdmin/AddPreviewToken', data, '', getState().auth.authToken);
    return dispatch({ type: ActionType.ADDPREVIEWTOKEN, payload: resp });
  } catch (error) {
    let res = [{'status': 404, 'error': 'Error in create preview token'}];
    return dispatch({ type: ActionType.ADDPREVIEWTOKEN, payload: res });
  }
}

export const getPreviewTokens = (recordId) => async (dispatch, getState, api) => {
  try {
    let resp = await api.get('bannerAdmin/GetPreviewTokens/', {}, getState().auth.authToken);
    return dispatch({ type: ActionType.GETPREVIEWTOKENS, payload: resp });
  } catch (error) {
    let res = [{'status': 404, 'error': 'Error in getting preview token list'}];
    return dispatch({ type: ActionType.GETPREVIEWTOKENS, payload: res });
  }
}
export const updatePreviewToken = (data) => async (dispatch, getState, api) => {
  try {
    let resp = await api.post('bannerAdmin/UpdatePreviewToken', data, getState().auth.authToken);
    return dispatch({ type: ActionType.UPDATEPREVIEWTOKEN, payload: resp });
  } catch (error) {
    let res = [{'status': 404, 'error': 'Error in create preview token'}];
    return dispatch({ type: ActionType.UPDATEPREVIEWTOKEN, payload: res });
  }
}
export const addPreviewTokenToBanners = (data) => async (dispatch, getState, api) => {
  try {
    let resp = await api.post('bannerAdmin/AddPreviewTokenToBanners', data, getState().auth.authToken);
    return dispatch({ type: ActionType.ADDPREVIEWTOKENTOBANNERS, payload: resp });
  } catch (error) {
    let res = [{'status': 404, 'error': 'Error in create preview token'}];
    return dispatch({ type: ActionType.ADDPREVIEWTOKENTOBANNERS, payload: res });
  }
}
