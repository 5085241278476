import React from 'react'
import { AppContent, AppSidebar, AppFooter, AppHeader } from '../components/index'

const EmptyLayout = (props) => {
  const {children} =props;
  return (
    <div>
      {children}
    </div>
  )
}

export default EmptyLayout
