import { ActionType } from "../model";

export const getShippingCompanyList = () => async (dispatch, getState, api) => {
  	try {
  		let resp = await api.get('ShippingCompanyAdmin/GetShippingCompanyList', {}, getState().auth.authToken);
  		return dispatch({ type: ActionType.GETSHIPPINGCOMPANYLIST, payload: resp });
  	} catch (error) {
      let res = [{'status': 404, 'error': 'Error in getting shipping company list'}];
  		return dispatch({ type: ActionType.GETSHIPPINGCOMPANYLIST, payload: res });
  	}
};

export const addShippingCompany = (data) => async (dispatch, getState, api) => {
  	try {
  		let resp = await api.post('ShippingCompanyAdmin/AddShippingCompany', data,'', getState().auth.authToken);
  		return dispatch({ type: ActionType.ADDSHIPPINGCOMPANY, payload: resp });
  	} catch (error) {
      let res = [{'status': 404, 'error': 'Error in adding shipping company'}];
  		return dispatch({ type: ActionType.ADDSHIPPINGCOMPANY, payload: res });
  	}
};

export const getShippingCompanyDetailById = (recordId) => async (dispatch, getState, api) => {
  try {
    let resp = await api.get('ShippingCompanyAdmin/GetShippingCompanyDetail/'+recordId, {}, getState().auth.authToken);
    return dispatch({ type: ActionType.GETSHIPPINGCOMPANYDETAILS, payload: resp });
  } catch (error) {
    let res = [{'status': 404, 'error': 'Error in getting shipping company detail'}];
    return dispatch({ type: ActionType.GETSHIPPINGCOMPANYDETAILS, payload: res });
  }
}

export const deleteShippingCompany = (recordId) => async (dispatch, getState, api) => {
  try {
    let resp = await api.delete('ShippingCompanyAdmin/DeleteShippingCompany/'+recordId, {}, getState().auth.authToken);
    return dispatch({ type: ActionType.DELETESHIPPINGCOMPANY, payload: resp });
  } catch (error) {
    let res = [{'status': 404, 'error': 'Error in deleting shipping company'}];
    return dispatch({ type: ActionType.DELETESHIPPINGCOMPANY, payload: res });
  }
}

export const updateShippingCompany = (data) => async (dispatch, getState, api) => {
  	try {
  		let resp = await api.put('ShippingCompanyAdmin/UpdateShippingCompany/'+data.id, data, getState().auth.authToken);
  		return dispatch({ type: ActionType.UPDATESHIPPINGCOMPANY, payload: resp });
  	} catch (error) {
      let res = [{'status': 404, 'error': 'Error in updating shipping company'}];
  		return dispatch({ type: ActionType.UPDATESHIPPINGCOMPANY, payload: res });
  	}
};
export const addShippingCountry = (data) => async (dispatch, getState, api) => {
  	try {
  		let resp = await api.post('ShippingCompanyAdmin/AddShippingCountry', data,'', getState().auth.authToken);
  		return dispatch({ type: ActionType.ADDSHIPPINGCOUNTRY, payload: resp });
  	} catch (error) {
      let res = [{'status': 404, 'error': 'Error in adding shipping company'}];
  		return dispatch({ type: ActionType.ADDSHIPPINGCOUNTRY, payload: res });
  	}
};
export const updateShippingCountry = (data) => async (dispatch, getState, api) => {
  	try {
  		let resp = await api.put('ShippingCompanyAdmin/UpdateShippingCountry?Id='+data.id, data, getState().auth.authToken);
  		return dispatch({ type: ActionType.UPDATESHIPPINGCOUNTRY, payload: resp });
  	} catch (error) {
      let res = [{'status': 404, 'error': 'Error in updating shipping company'}];
  		return dispatch({ type: ActionType.UPDATESHIPPINGCOUNTRY, payload: res });
  	}
};

export const getShippingCountryList = () => async (dispatch, getState, api) => {
  	try {
  		let resp = await api.get('ShippingCompanyAdmin/GetShippingCountryList', {}, getState().auth.authToken);
  		return dispatch({ type: ActionType.GETSHIPPINGCOUNTRYLIST, payload: resp });
  	} catch (error) {
      let res = [{'status': 404, 'error': 'Error in getting shipping company list'}];
  		return dispatch({ type: ActionType.GETSHIPPINGCOUNTRYLIST, payload: res });
  	}
};
export const deleteShippingCountry = (recordId) => async (dispatch, getState, api) => {
  try {
    let resp = await api.delete('ShippingCompanyAdmin/DeleteShippingCountry/'+recordId, {}, getState().auth.authToken);
    return dispatch({ type: ActionType.DELETESHIPPINGCOUNTRY, payload: resp });
  } catch (error) {
    let res = [{'status': 404, 'error': 'Error in deleting shipping country'}];
    return dispatch({ type: ActionType.DELETESHIPPINGCOUNTRY, payload: res });
  }
};
export const getShippingCompanyProvincesList = (data) => async (dispatch, getState, api) => {
  	try {
  		let resp = await api.get('ShippingCompanyAdmin/GetShippingCompanyProvincesList?ShippingCompanyId='+data.ShippingCompanyId+'&CountryId='+data.CountryId, {}, getState().auth.authToken);
  		return dispatch({ type: ActionType.GETSHIPPINGCOMPANYPROVINCESLIST, payload: resp });
  	} catch (error) {
      let res = [{'status': 404, 'error': 'Error in getting shipping company list'}];
  		return dispatch({ type: ActionType.GETSHIPPINGCOMPANYPROVINCESLIST, payload: res });
  	}
};
export const updateShippingCompanyProvinces = (data) => async (dispatch, getState, api) => {
  	try {
  		let resp = await api.put('ShippingCompanyAdmin/UpdateShippingCompanyProvinces?Id='+data.id, data, getState().auth.authToken);
  		return dispatch({ type: ActionType.UPDATESHIPPINGCOMPANYPROVINCES, payload: resp });
  	} catch (error) {
      let res = [{'status': 404, 'error': 'Error in updating shipping company'}];
  		return dispatch({ type: ActionType.UPDATESHIPPINGCOMPANYPROVINCES, payload: res });
  	}
};
export const deleteShippingCompanyProvinces = (recordId) => async (dispatch, getState, api) => {
  try {
    let resp = await api.delete('ShippingCompanyAdmin/DeleteShippingCompanyProvinces​/'+recordId, {}, getState().auth.authToken);
    return dispatch({ type: ActionType.DELETESHIPPINGCOMPANYPROVINCES, payload: resp });
  } catch (error) {
    let res = [{'status': 404, 'error': 'Error in deleting shipping country'}];
    return dispatch({ type: ActionType.DELETESHIPPINGCOMPANYPROVINCES, payload: res });
  }
};
export const addShippingCompanyProvinces = (data) => async (dispatch, getState, api) => {
  	try {
  		let resp = await api.post('ShippingCompanyAdmin/AddShippingCompanyProvinces', data, '',getState().auth.authToken);
  		return dispatch({ type: ActionType.ADDSHIPPINGCOMPANYPROVINCES, payload: resp });
  	} catch (error) {
      let res = [{'status': 404, 'error': 'Error in adding shipping company'}];
  		return dispatch({ type: ActionType.ADDSHIPPINGCOMPANYPROVINCES, payload: res });
  	}
};
