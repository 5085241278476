import { ActionType } from "../model";


export const getEditionDatesFromSubscription = (id) => async (dispatch, getState, api) => {
  	try {
  		let resp = await api.get('OrderAdmin/GetEditionDatesFromSubscription?subscriptionId='+ id, {}, getState().auth.authToken);
  		return dispatch({ type: ActionType.GETEDITIONDATESFROMSUBSCRIPTION, payload: resp });
  	} catch (error) {
        let res = [{'status': 404, 'error': 'Error in getEditionDatesFromSubscription'}];
  		return dispatch({ type: ActionType.GETEDITIONDATESFROMSUBSCRIPTION, payload: res });
  	}
};
export const getSubscriptionOrdersByDate = (data) => async (dispatch, getState, api) => {
    try {
        let resp = await api.post('OrderAdmin/GetSubscriptionOrdersByDate', data, '', getState().auth.authToken);
        return dispatch({ type: ActionType.GETSUBSCRIPTIONORDERSBYDATE, payload: resp });
    } catch (error) {
    let res = [{'status': 404, 'error': 'Error in getSubscriptionOrdersByDate'}];
        return dispatch({ type: ActionType.GETSUBSCRIPTIONORDERSBYDATE, payload: res });
    }
}
export const trySubscriptionOrderPayment = (id) => async (dispatch, getState, api) => {
    try {
        let resp = await api.get('OrderAdmin/TrySubscriptionOrderPayment?orderId='+ id, {}, getState().auth.authToken);
        return dispatch({ type: ActionType.TRYSUBSCRIPTIONORDERPAYMENT, payload: resp });
    } catch (error) {
      let res = [{'status': 404, 'error': 'Error in trySubscriptionOrderPayment'}];
        return dispatch({ type: ActionType.TRYSUBSCRIPTIONORDERPAYMENT, payload: res });
    }
};
export const getPOSTransactionDetails = (id) => async (dispatch, getState, api) => {
    try {
        let resp = await api.get('OrderAdmin/GetPOSTransactionlog?orderId='+ id, {}, getState().auth.authToken);
        return dispatch({ type: ActionType.GETPOSTRANSACTIONLOG, payload: resp });
    } catch (error) {
      let res = [{'status': 404, 'error': 'Error in GetPOSTransactionlog'}];
        return dispatch({ type: ActionType.GETPOSTRANSACTIONLOG, payload: res });
    }
};
export const SendEmailResubscribe = (data) => async (dispatch, getState, api) => {
    try {
        let resp = await api.get('OrderAdmin/SendEmailResubscribe?oid='+ data.id +'&oe='+ data.customerEmail , {}, getState().auth.authToken);
        return dispatch({ type: ActionType.TRYSUBSCRIPTIONORDERPAYMENT, payload: resp });
    } catch (error) {
      let res = [{'status': 404, 'error': 'Error in SendEmailResubscribe'}];
        return dispatch({ type: ActionType.TRYSUBSCRIPTIONORDERPAYMENT, payload: res });
    }
};