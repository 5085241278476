import { ActionType } from "../model";

export const getProductCategoriesByShop = (data) => async (dispatch, getState, api) => {
    try {
        const resp = await api.post('ProductsAdmin/GetProductCategoriesByShop', data, {}, getState().auth.authToken);
        return dispatch({ type: ActionType.GETPRODUCTCATEGORIESBYSHOP, payload: resp });
    } catch (error) {
        const res = [{'status': 404, 'error': 'Error in GetProductCategoriesByShop'}];
        return dispatch({ type: ActionType.GETPRODUCTCATEGORIESBYSHOP, payload: res });
    }
};
export const updateProductCategories = (data) => async (dispatch, getState, api) => {
    try {
        const resp = await api.post('ProductsAdmin/UpdateProductCategories', data, {}, getState().auth.authToken);
        return dispatch({ type: ActionType.UPDTEPRODUCTCATEGORIES, payload: resp });
    } catch (error) {
        const res = [{'status': 404, 'error': 'Error in UpdateProductCategories'}];
        return dispatch({ type: ActionType.UPDTEPRODUCTCATEGORIES, payload: res });
    }
};

export const deleteProductCategories = (data) => async (dispatch, getState, api) => {
    try {
        const resp = await api.post('ProductsAdmin/DeleteProductCategories', data, {}, getState().auth.authToken);
        return dispatch({ type: ActionType.DELETEPRODUCTCATEGORIES, payload: resp });
    } catch (error) {
        const res = [{'status': 404, 'error': 'Error in DeleteProductCategories'}];
        return dispatch({ type: ActionType.DELETEPRODUCTCATEGORIES, payload: res });
    }
};

export const GetProductTexts = (data) => async (dispatch, getState, api) => {
    try {
        let resp = await api.post('ProductsAdmin/GetProductTexts', data, {}, getState().auth.authToken);
        return dispatch({ type: ActionType.GETPRODUCTTEXTS, payload: resp });
    } catch (error) {
    let res = [{'status': 404, 'error': 'Error in GetProductTexts'}];
        return dispatch({ type: ActionType.GETPRODUCTTEXTS, payload: res });
    }
};
export const UpdateProductTexts = (data) => async (dispatch, getState, api) => {
    try {
        const resp = await api.post('ProductsAdmin/UpdateProductTexts', data, {}, getState().auth.authToken);
        return dispatch({ type: ActionType.UPDATEPRODUCTTEXTS, payload: resp });
    } catch (error) {
        const res = [{'status': 404, 'error': 'Error in UpdateProductTexts'}];
        return dispatch({ type: ActionType.UPDATEPRODUCTTEXTS, payload: res });
    }
};

export const GetProductMediaReviews = (data) => async (dispatch, getState, api) => {
    try {
        let resp = await api.post('ProductsAdmin/GetProductMediaReviews', data, {}, getState().auth.authToken);
        return dispatch({ type: ActionType.GETPRODUCTMEDIAREVIEWS, payload: resp });
    } catch (error) {
    let res = [{'status': 404, 'error': 'Error in GetProductMediaReviews'}];
        return dispatch({ type: ActionType.GETPRODUCTMEDIAREVIEWS, payload: res });
    }
};
export const UpdateProductMediaReview = (data) => async (dispatch, getState, api) => {
    try {
        const resp = await api.post('ProductsAdmin/UpdateProductMediaReview', data, {}, getState().auth.authToken);
        return dispatch({ type: ActionType.UPDATEPRODUCTTEXTS, payload: resp });
    } catch (error) {
        const res = [{'status': 404, 'error': 'Error in UpdateProductMediaReview'}];
        return dispatch({ type: ActionType.UPDATEPRODUCTTEXTS, payload: res });
    }
};
export const AddProductMediaReview = (data) => async (dispatch, getState, api) => {
    try {
        const resp = await api.post('ProductsAdmin/AddProductMediaReview', data, {}, getState().auth.authToken);
        return dispatch({ type: ActionType.ADDPRODUCTMEDIAREVIEW, payload: resp });
    } catch (error) {
        const res = [{'status': 404, 'error': 'Error in AddProductMediaReview'}];
        return dispatch({ type: ActionType.ADDPRODUCTMEDIAREVIEW, payload: res });
    }
};
export const DeleteProductMediaReview = (data) => async (dispatch, getState, api) => {
    try {
        const resp = await api.post('ProductsAdmin/DeleteProductMediaReview', data, {}, getState().auth.authToken);
        return dispatch({ type: ActionType.ADDPRODUCTMEDIAREVIEW, payload: resp });
    } catch (error) {
        const res = [{'status': 404, 'error': 'Error in DeleteProductMediaReview'}];
        return dispatch({ type: ActionType.ADDPRODUCTMEDIAREVIEW, payload: res });
    }
};
export const GetProductPressMaterial = (data) => async (dispatch, getState, api) => {
    try {
        let resp = await api.post('ProductsAdmin/GetProductPressMaterial', data, {}, getState().auth.authToken);
        return dispatch({ type: ActionType.GETPRODUCTPRESSMATERIAL, payload: resp });
    } catch (error) {
    let res = [{'status': 404, 'error': 'Error in GetProductPressMaterial'}];
        return dispatch({ type: ActionType.GETPRODUCTPRESSMATERIAL, payload: res });
    }
};
export const UpdateProductPressMaterial = (data) => async (dispatch, getState, api) => {
    try {
        const resp = await api.post('ProductsAdmin/UpdateProductPressMaterial', data, {}, getState().auth.authToken);
        return dispatch({ type: ActionType.UPDATEPRODUCTPRESSMATERIAL, payload: resp });
    } catch (error) {
        const res = [{'status': 404, 'error': 'Error in UpdateProductPressMaterial'}];
        return dispatch({ type: ActionType.UPDATEPRODUCTPRESSMATERIAL, payload: res });
    }
};
export const AddProductPressMaterial = (data) => async (dispatch, getState, api) => {
    try {
        const resp = await api.post('ProductsAdmin/AddProductPressMaterial', data, {}, getState().auth.authToken);
        return dispatch({ type: ActionType.ADDPRODUCTPRESSMATERIAL, payload: resp });
    } catch (error) {
        const res = [{'status': 404, 'error': 'Error in AddProductPressMaterial'}];
        return dispatch({ type: ActionType.ADDPRODUCTPRESSMATERIAL, payload: res });
    }
};
export const DeleteProductPressMaterial = (data) => async (dispatch, getState, api) => {
    try {
        const resp = await api.post('ProductsAdmin/DeleteProductPressMaterial', data, {}, getState().auth.authToken);
        return dispatch({ type: ActionType.DELETEPRODUCTPRESSMATERIAL, payload: resp });
    } catch (error) {
        const res = [{'status': 404, 'error': 'Error in DeleteProductPressMaterial'}];
        return dispatch({ type: ActionType.DELETEPRODUCTPRESSMATERIAL, payload: res });
    }
};
export const GetProductAuthors = (data) => async (dispatch, getState, api) => {
    try {
        let resp = await api.post('ProductsAdmin/GetProductAuthors', data, {}, getState().auth.authToken);
        return dispatch({ type: ActionType.GETPRODUCTAUTHORS, payload: resp });
    } catch (error) {
    let res = [{'status': 404, 'error': 'Error in GetProductAuthors'}];
        return dispatch({ type: ActionType.GETPRODUCTAUTHORS, payload: res });
    }
};
export const UpdateProductAuthor = (data) => async (dispatch, getState, api) => {
    try {
        let resp = await api.post('ProductsAdmin/UpdateProductAuthor', data, {}, getState().auth.authToken);
        return dispatch({ type: ActionType.GETPRODUCTAUTHORS, payload: resp });
    } catch (error) {
    let res = [{'status': 404, 'error': 'Error in UpdateProductAuthor'}];
        return dispatch({ type: ActionType.GETPRODUCTAUTHORS, payload: res });
    }
};
export const GetProductsPendingToServeWithoutStock = (data) => async (dispatch, getState, api) => {
    try {		
        let resp = await api.get('ProductsAdmin/GetProductsPendingToServeWithoutStock', {}, getState().auth.authToken);
        return dispatch({ type: ActionType.GETPRODUTCSPENDINGTOSERVEWITHOUTSTOCK, payload: resp });
    } catch (error) {
    let res = [{'status': 404, 'error': 'Error in GetProductsPendingToServeWithoutStock'}];
        return dispatch({ type: ActionType.GETPRODUTCSPENDINGTOSERVEWITHOUTSTOCK, payload: res });
    }
};
export const CreateProductTransferOrders = (data) => async (dispatch, getState, api) => {
    try {
        let resp = await api.post('ProductsAdmin/CreateProductTransferOrders', data, {}, getState().auth.authToken);
        return dispatch({ type: ActionType.CREATETRANSFERORDER, payload: resp });
    } catch (error) {
    let res = [{'status': 404, 'error': 'Error in CreateProductTransferOrders'}];
        return dispatch({ type: ActionType.CREATETRANSFERORDER, payload: res });
    }
};

