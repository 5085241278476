import React, { Component, useEffect } from 'react'
import "antd/dist/antd.css";

import { connect } from "react-redux";
import { Router } from 'react-router-dom';
import { history } from "./redux/store";
import Routes from './routes';


const App =  (props) => {
    let isAuthenticated = '';

    if(props?.auth?.hasOwnProperty("authToken")) {      
      isAuthenticated = !!props?.auth?.authToken
    }

    return (
        <Router history={history}>
           <Routes isAuthenticated={isAuthenticated} />
        </Router>
    )
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
  };
}

export default connect(mapStateToProps)(App);
/*Rebuild*/
/*Rebuild*/
/*Rebuild*/