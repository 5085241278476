
export default function createReducer(
	initialState,
	handlers
) {
	const r = (state= initialState, action) => {
		if (handlers.hasOwnProperty(action.type)) {
			return handlers[action.type](state, action);
		} else {
			return state;
		}
	};

	return r;
}
