import { ActionType } from "../model";

export const getBannerList = () => async (dispatch, getState, api) => {
  	try {
  		let resp = await api.get('bannerAdmin/GetBannerList?PageNo=1&PageSize=10', {}, getState().auth.authToken);
  		return dispatch({ type: ActionType.GETBANNER, payload: resp });
  	} catch (error) {
      let res = [{'status': 404, 'error': 'Error in getting banner list'}];
  		return dispatch({ type: ActionType.GETBANNER, payload: res });
  	}
};

export const getBannerPositions = () => async (dispatch, getState, api) => {
  	try {
  		let resp = await api.get('bannerAdmin/GetPositions', {}, getState().auth.authToken);
  		return dispatch({ type: ActionType.GETPOSITIONS, payload: resp });
  	} catch (error) {
      let res = [{'status': 404, 'error': 'Error in getting banner positions'}];
  		return dispatch({ type: ActionType.GETPOSITIONS, payload: res });
  	}
};


export const addBannerList = (data) => async (dispatch, getState, api) => {
  	try {
  		let resp = await api.post('bannerAdmin/AddBanner', data, '', getState().auth.authToken);
  		return dispatch({ type: ActionType.ADDBANNER, payload: resp });
  	} catch (error) {
      let res = [{'status': 404, 'error': 'Error in addling banner list'}];
  		return dispatch({ type: ActionType.ADDBANNER, payload: res });
  	}
};

export const updateBannerList = (data) => async (dispatch, getState, api) => {
  	try {
  		let resp = await api.put('bannerAdmin/UpdateBanner/'+data.id, data, '', getState().auth.authToken);
  		return dispatch({ type: ActionType.UPDATEBANNER, payload: resp });
  	} catch (error) {
      let res = [{'status': 404, 'error': 'Error in updating banner list'}];
  		return dispatch({ type: ActionType.UPDATEBANNER, payload: res });
  	}
};

export const getBannerDetailById = (recordId) => async (dispatch, getState, api) => {
  try {
    let resp = await api.get('bannerAdmin/GetBannerDetail/'+recordId, {}, getState().auth.authToken);
    return dispatch({ type: ActionType.GETBANNERDETAILS, payload: resp });
  } catch (error) {
    let res = [{'status': 404, 'error': 'Error in getting banner details by id'}];
    return dispatch({ type: ActionType.GETBANNERDETAILS, payload: res });
  }
}

export const deleteBannerById = (recordId) => async (dispatch, getState, api) => {
  try {
    let resp = await api.delete('bannerAdmin/DeleteBanner/'+recordId, {}, '', getState().auth.authToken);
    return dispatch({ type: ActionType.DELETEBANNER, payload: resp });
  } catch (error) {
    let res = [{'status': 404, 'error': 'Error in deleting banner by id'}];
    return dispatch({ type: ActionType.DELETEBANNER, payload: res });
  }
}

/*get all shops*/
export const getallShopId = () => async (dispatch, getState, api) => {
  try {
    let resp = await api.get('Shops/GetShopsList?pageNo=1&pageSize=999', {}, '', getState().auth.authToken);
    return dispatch({ type: ActionType.SHOPID, payload: resp });
  } catch (error) {
    let res = [{'status': 404, 'error': 'Error in getting shop list'}];
    return dispatch({ type: ActionType.SHOPID, payload: res });
  }
}


export const addEvent = (data) => async (dispatch, getState, api) => {
  try {
    let resp = await api.post('ScheduleAdmin/AddEvent', data, '', getState().auth.authToken);
    return dispatch({ type: ActionType.ADDEVENT, payload: resp });
  } catch (error) {
    let res = [{'status': 404, 'error': 'Error in adding event'}];
    return dispatch({ type: ActionType.ADDEVENT, payload: res });
  }
};

export const getEvent = (data) => async (dispatch, getState, api) => {
  try {
    let resp = await api.get('ScheduleAdmin/GetEventsList', data,  getState().auth.authToken);
    return dispatch({ type: ActionType.GETEVENT, payload: resp });
  } catch (error) {
    let res = [{'status': 404, 'error': 'Error in getting event list'}];
    return dispatch({ type: ActionType.GETEVENT, payload: res });
  }
};

///api/ScheduleAdmin/GetEvent/{id}
export const geteventdetailByID = (recordId) => async (dispatch, getState, api) => {
  try {
    let resp = await api.get('ScheduleAdmin/GetEvent/'+recordId, {}, getState().auth.authToken);
    return dispatch({ type: ActionType.EVENTDETAILS, payload: resp });
  } catch (error) {
    let res = [{'status': 404, 'error': 'Error in getting event detail by id'}];
    return dispatch({ type: ActionType.EVENTDETAILS, payload: res });
  }
}

export const deleteeventById = (recordId) => async (dispatch, getState, api) => {
  try {
    let resp = await api.delete('ScheduleAdmin/DeleteEvent/'+recordId, {}, '', getState().auth.authToken);
    return dispatch({ type: ActionType.DELETEEVENT, payload: resp });
  } catch (error) {
    let res = [{'status': 404, 'error': 'Error in deleting event by id'}];
    return dispatch({ type: ActionType.DELETEEVENT, payload: res });
  }
}


export const updateEvent = (data) => async (dispatch, getState, api) => {
  try {
    let resp = await api.put('ScheduleAdmin/UpdateEvent', data, '', getState().auth.authToken);
    return dispatch({ type: ActionType.UPDATEEVENT, payload: resp });
  } catch (error) {
    let res = [{'status': 404, 'error': 'Error in updating event'}];
    return dispatch({ type: ActionType.UPDATEEVENT, payload: res });
  }
};

export const getAllProvinces = (recordId) => async (dispatch, getState, api) => {
  try {
    let resp = await api.get(`Common/GetAllProvinces?countryId=${recordId}`, {}, getState().auth.authToken);
    return dispatch({ type: ActionType.ALLPROVINCES, payload: resp });
  } catch (error) {
    let res = [{'status': 404, 'error': 'Error in getting provinces list'}];
    return dispatch({ type: ActionType.ALLPROVINCES, payload: res });
  }
}

export const getAllEventTypelist = (recordId) => async (dispatch, getState, api) => {
  try {
    let resp = await api.get('Schedule/GetEventsTypesList', {}, getState().auth.authToken);
    return dispatch({ type: ActionType.EVENTTYPELIST, payload: resp });
  } catch (error) {
    let res = [{'status': 404, 'error': 'Error in getting event types list'}];
    return dispatch({ type: ActionType.EVENTTYPELIST, payload: res });
  }
}
export const addEventProduct = (data) => async (dispatch, getState, api) => {
  try {
    let resp = await api.post('ScheduleAdmin/addEventProduct', data, '', getState().auth.authToken);
    return dispatch({ type: ActionType.ADDEVENTPRODUCT, payload: resp });
  } catch (error) {
    let res = [{'status': 404, 'error': 'Error in adding event product'}];
    return dispatch({ type: ActionType.ADDEVENTPRODUCT, payload: res });
  }
};
export const deleteEventProduct = (data) => async (dispatch, getState, api) => {
  try {
    let resp = await api.post('ScheduleAdmin/deleteEventProduct', data, '', getState().auth.authToken);
    return dispatch({ type: ActionType.DELETEEVENTPRODUCT, payload: resp });
  } catch (error) {
    let res = [{'status': 404, 'error': 'Error in deleting event product'}];
    return dispatch({ type: ActionType.DELETEEVENTPRODUCT, payload: res });
  }
};