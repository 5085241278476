import { combineReducers } from "redux";

import * as authReducer from "./auth";
import * as bannerReducer from "./banner";


export default () =>
	combineReducers({
		...authReducer,
		...bannerReducer
	});
