import { ActionType } from "../model";

export const getStorageList = () => async (dispatch, getState, api) => {
  	try {
  		let resp = await api.get('StorageAdmin/GetStorageList', {}, getState().auth.authToken);
  		return dispatch({ type: ActionType.GETSTORAGELIST, payload: resp });
  	} catch (error) {
      let res = [{'status': 404, 'error': 'Error in getting storage list'}];
  		return dispatch({ type: ActionType.GETSTORAGELIST, payload: res });
  	}
};
