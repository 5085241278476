import React from 'react'

const AppFooter = () => {
  return (    
    <div className="footer">
        <a href="#"  rel="noopener noreferrer">
          Ecc Comics
        </a>
        <span className="ms-1">&copy; 2023.</span>
    </div>    
  )
}

export default React.memo(AppFooter)
