import { ActionType } from "../model";

export const getWebPageList = () => async (dispatch, getState, api) => {
  	try {
  		let resp = await api.get('WebPageAdmin/GetWebPageList', {}, getState().auth.authToken);
  		return dispatch({ type: ActionType.GETWEBPAGELIST, payload: resp });
  	} catch (error) {
      let res = [{'status': 404, 'error': 'Error in getting web page list'}];
  		return dispatch({ type: ActionType.GETWEBPAGELIST, payload: res });
  	}
};

export const addWebPage = (data) => async (dispatch, getState, api) => {
  	try {
  		let resp = await api.post('WebPageAdmin/AddWebPage', data, getState().auth.authToken);
  		return dispatch({ type: ActionType.ADDWEBPAGE, payload: resp });
  	} catch (error) {
      let res = [{'status': 404, 'error': 'Error in adding web Page'}];
  		return dispatch({ type: ActionType.ADDWEBPAGE, payload: res });
  	}
};

export const updateWebPage = (data) => async (dispatch, getState, api) => {
  	try {
  		let resp = await api.post('WebPageAdmin/UpdateWebPage', data, getState().auth.authToken);
  		return dispatch({ type: ActionType.UPDATEWEBPAGE, payload: resp });
  	} catch (error) {
      let res = [{'status': 404, 'error': 'Error in updating web Page'}];
  		return dispatch({ type: ActionType.UPDATEWEBPAGE, payload: res });
  	}
};

export const getWebPage = (id) => async (dispatch, getState, api) => {
  try {
    let resp = await api.get('WebPageAdmin/WebPage/'+id, {}, getState().auth.authToken);
    return dispatch({ type: ActionType.GETWEBPAGE, payload: resp });
  } catch (error) {
    let res = [{'status': 404, 'error': 'Error in getting web page'}];
    return dispatch({ type: ActionType.GETWEBPAGE, payload: res });
  }
}
export const deleteWebPage = (id) => async (dispatch, getState, api) => {
  try {
    let resp = await api.delete('WebPageAdmin/deleteWebPage/'+id, {}, '', getState().auth.authToken);
    return dispatch({ type: ActionType.DELETEWEBPAGE, payload: resp });
  } catch (error) {
    let res = [{'status': 404, 'error': 'Error deleting web page'}];
    return dispatch({ type: ActionType.DELETEWEBPAGE, payload: res });
  }
}
