import { ActionType } from "../model";

export const getAttributesValuesList = () => async (dispatch, getState, api) => {
    try {
        let resp = await api.get('AttributeAdmin/GetAttributesValuesList', {}, getState().auth.authToken);
        return dispatch({ type: ActionType.GETATTRIBUTESVALUESLIST, payload: resp });
    } catch (error) {
    let res = [{'status': 404, 'error': 'Error in getting attributesValues list'}];
        return dispatch({ type: ActionType.GETATTRIBUTESVALUESLIST, payload: res });
    }
};
export const getAttributesList = () => async (dispatch, getState, api) => {
    try {
        let resp = await api.post('AttributeAdmin/GetAttributesList', {},'', getState().auth.authToken);
        return dispatch({ type: ActionType.GETATTRIBUTESLIST, payload: resp });
    } catch (error) {
    let res = [{'status': 404, 'error': 'Error in getting getAttributesList'}];
        return dispatch({ type: ActionType.GETATTRIBUTESLIST, payload: res });
    }
};
export const getAttributeDetail = (attributeId) => async (dispatch, getState, api) => {
    try {
        let resp = await api.get('AttributeAdmin/GetAttributeDetail/'+ attributeId, {}, getState().auth.authToken);
        return dispatch({ type: ActionType.GETATTRIBUTEDETAIL, payload: resp });
    } catch (error) {
    let res = [{'status': 404, 'error': 'Error in getting GetAttributeDetail'}];
        return dispatch({ type: ActionType.GETATTRIBUTEDETAIL, payload: res });
    }
};
export const getAttributeTypes = () => async (dispatch, getState, api) => {
    try {
        let resp = await api.get('AttributeAdmin/GetAttributeTypes', {}, getState().auth.authToken);
        return dispatch({ type: ActionType.GETATTRIBUTETYPES, payload: resp });
    } catch (error) {
    let res = [{'status': 404, 'error': 'Error in getting GetAttributeTypes list'}];
        return dispatch({ type: ActionType.GETATTRIBUTETYPES, payload: res });
    }
};
export const getAttributeValues = (attributeId) => async (dispatch, getState, api) => {
    try {
        let resp = await api.get('AttributeValuesAdmin/GetAttributeValues/'+ attributeId, {}, getState().auth.authToken);
        return dispatch({ type: ActionType.GETATTRIBUTEVALUES, payload: resp });
    } catch (error) {
    let res = [{'status': 404, 'error': 'Error in getting attributesValues list'}];
        return dispatch({ type: ActionType.GETATTRIBUTEVALUES, payload: res });
    }
};
export const getAttributeValuesDetail = (ValueId) => async (dispatch, getState, api) => {
    try {
        let resp = await api.get('AttributeValuesAdmin/GetAttributeValuesDetail/'+ ValueId, {}, getState().auth.authToken);
        return dispatch({ type: ActionType.GETATTRIBUTEVALUEDETAIL, payload: resp });
    } catch (error) {
    let res = [{'status': 404, 'error': 'Error in getting attributesValues list'}];
        return dispatch({ type: ActionType.GETATTRIBUTEVALUEDETAIL, payload: res });
    }
};
export const updateAttributeValue = (value) => async (dispatch, getState, api) => {
    try {        
        let resp = await api.put('AttributeValuesAdmin/UpdateAttributeValue/'+ value.id, value, '', getState().auth.authToken);
        return dispatch({ type: ActionType.UPDATEATTRIBUTEVALUE, payload: resp });
    } catch (error) {
    let res = [{'status': 404, 'error': 'Error in getting attributesValues list'}];
        return dispatch({ type: ActionType.UPDATEATTRIBUTEVALUE, payload: res });
    }
};
export const updateAttribute = (attribute) => async (dispatch, getState, api) => {
    try {        
        let resp = await api.put('AttributeAdmin/UpdateAttribute/', attribute, '', getState().auth.authToken);
        return dispatch({ type: ActionType.UPDATEATTRIBUTE, payload: resp });
    } catch (error) {
    let res = [{'status': 404, 'error': 'Error in getting attributesValues list'}];
        return dispatch({ type: ActionType.UPDATEATTRIBUTE, payload: res });
    }
};
export const insertAttribute = (attribute) => async (dispatch, getState, api) => {
    try {        
        let resp = await api.post('AttributeAdmin/AddAttribute', attribute, '', getState().auth.authToken);
        return dispatch({ type: ActionType.INSERTATTRIBUTE, payload: resp });
    } catch (error) {
    let res = [{'status': 404, 'error': 'Error in AddAttribute'}];
        return dispatch({ type: ActionType.INSERTATTRIBUTE, payload: res });
    }
};
export const insertAttributeValue = (value) => async (dispatch, getState, api) => {
    try {        
        let resp = await api.post('AttributeValuesAdmin/AddAttributeValues', value, '', getState().auth.authToken);
        return dispatch({ type: ActionType.INSERTATTRIBUTEVALUE, payload: resp });
    } catch (error) {
    let res = [{'status': 404, 'error': 'Error in AddAttributeValues'}];
        return dispatch({ type: ActionType.INSERTATTRIBUTEVALUE, payload: res });
    }
};
export const deleteAttributeValue = (value) => async (dispatch, getState, api) => {
    try {        
        let resp = await api.delete('AttributeValuesAdmin/DeleteAttributeValues/'+ value.id, value, '', getState().auth.authToken);
        return dispatch({ type: ActionType.DELETEATTRIBUTEVALUE, payload: resp });
    } catch (error) {
    let res = [{'status': 404, 'error': 'Error in DeleteAttributeValues'}];
        return dispatch({ type: ActionType.DELETEATTRIBUTEVALUE, payload: res });
    }
};
export const deleteAttribute = (attribute) => async (dispatch, getState, api) => {
    try {               
        let resp = await api.delete('AttributeAdmin/DeleteAttribute/'+ attribute.id, {}, '', getState().auth.authToken);
        return dispatch({ type: ActionType.DELETEATTRIBUTE, payload: resp });
    } catch (error) {
    let res = [{'status': 404, 'error': 'Error in DeleteAttributeValues'}];
        return dispatch({ type: ActionType.DELETEATTRIBUTE, payload: res });
    }
};
export const getProductAttributeValuesSelected = (productId) => async (dispatch, getState, api) => {
    try {
        let resp = await api.get('ProductsAttributesValues/GetProductAttributeValuesSelectedList/'+ productId, {}, getState().auth.authToken);
        return dispatch({ type: ActionType.GETPRODUCTATTRIBUTESVALUESSELECTED, payload: resp });
    } catch (error) {
    let res = [{'status': 404, 'error': 'Error in getting attributesValues list'}];
        return dispatch({ type: ActionType.GETPRODUCTATTRIBUTESVALUESSELECTED, payload: res });
    }
};
export const udpdateProductsAttributesValues = (attributesValues) => async (dispatch, getState, api) => {
    try {        
        let resp = await api.post('ProductsAttributesValues/UpdateProductsAttributesValues', attributesValues, '', getState().auth.authToken);
        return dispatch({ type: ActionType.UPDATEPRODUCTSATTRIBUTESVALUES, payload: resp });
    } catch (error) {
    let res = [{'status': 404, 'error': 'Error in getting attributesValues list'}];
        return dispatch({ type: ActionType.UPDATEPRODUCTSATTRIBUTESVALUES, payload: res });
    }
};
export const addShopsToAttribute = (shopsAttribute) => async (dispatch, getState, api) => {
    try {        
        let resp = await api.post('AttributeAdmin/AddShopsToAttribute', shopsAttribute, '', getState().auth.authToken);
        return dispatch({ type: ActionType.INSERTATTRIBUTE, payload: resp });
    } catch (error) {
    let res = [{'status': 404, 'error': 'Error in AddAttribute'}];
        return dispatch({ type: ActionType.INSERTATTRIBUTE, payload: res });
    }
};




