import { ActionType } from "../model";

export const getWebTextList = () => async (dispatch, getState, api) => {
  	try {
  		let resp = await api.get('WebTextsAdmin/GetWebTextsList', {}, getState().auth.authToken);
  		return dispatch({ type: ActionType.GETWEBTEXTLIST, payload: resp });
  	} catch (error) {
      let res = [{'status': 404, 'error': 'Error in getting web text list'}];
  		return dispatch({ type: ActionType.GETWEBTEXTLIST, payload: res });
  	}
};

export const addWebTexts = (data) => async (dispatch, getState, api) => {
  	try {
  		let resp = await api.post('WebTextsAdmin/AddWebTexts', data, '', getState().auth.authToken);
  		return dispatch({ type: ActionType.ADDWEBTEXTS, payload: resp });
  	} catch (error) {
      let res = [{'status': 404, 'error': 'Error in getting banner list'}];
  		return dispatch({ type: ActionType.ADDWEBTEXTS, payload: res });
  	}
};

export const updateWebTexts = (data) => async (dispatch, getState, api) => {
  	try {
  		let resp = await api.put('WebTextsAdmin/UpdateWebTexts?Id='+data.id, data, getState().auth.authToken);
  		return dispatch({ type: ActionType.UPDATEWEBTEXTS, payload: resp });
  	} catch (error) {
      let res = [{'status': 404, 'error': 'Error in getting banner list'}];
  		return dispatch({ type: ActionType.UPDATEWEBTEXTS, payload: res });
  	}
};

export const getWebTextDetailById = (recordId) => async (dispatch, getState, api) => {
  try {
    let resp = await api.get('WebTextsAdmin/GetWebTextDetail/'+recordId, {}, getState().auth.authToken);
    return dispatch({ type: ActionType.GETWEBTEXTDETAILS, payload: resp });
  } catch (error) {
    let res = [{'status': 404, 'error': 'Error in getting banner list'}];
    return dispatch({ type: ActionType.GETWEBTEXTDETAILS, payload: res });
  }
}
export const deleteWebText = (recordId) => async (dispatch, getState, api) => {
  try {
    let resp = await api.delete('WebTextsAdmin/DeleteWebText/'+recordId, {}, '', getState().auth.authToken);
    return dispatch({ type: ActionType.DELETEBANNER, payload: resp });
  } catch (error) {
    let res = [{'status': 404, 'error': 'Error in deleting web text'}];
    return dispatch({ type: ActionType.DELETEBANNER, payload: res });
  }
}
