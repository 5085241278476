import { ActionType } from "../model";

export const getMenuList = () => async (dispatch, getState, api) => {
  	try {
  		const resp = await api.get('MenuAdmin/GetWebsiteMenuList', {}, getState().auth.authToken);
  		return dispatch({ type: ActionType.GETMENULIST, payload: resp });
  	} catch (error) {
        const res = [{'status': 404, 'error': 'Error in getting menu list'}];
  		return dispatch({ type: ActionType.GETMENULIST, payload: res });
  	}
};
export const updateMenu = (data) => async(dispatch, getState, api) => {
    try {
        const resp = await api.post('MenuAdmin/UpdateMenu',  data, '', getState().auth.authToken);
        return dispatch({ type: ActionType.UPDATEMENU, payload: resp });
    } catch (error) {
      const res = [{'status': 404, 'error': 'Error in UpdateMenu'}];
        return dispatch({ type: ActionType.UPDATEMENU, payload: res });
    }
};
export const addMenu = (data) => async(dispatch, getState, api) => {
    try {
        const resp = await api.post('MenuAdmin/AddMenu',  data, '', getState().auth.authToken);
        return dispatch({ type: ActionType.ADDMENU, payload: resp });
    } catch (error) {
      const res = [{'status': 404, 'error': 'Error in AddMenu'}];
        return dispatch({ type: ActionType.ADDMENU, payload: res });
    }
};
export const deleteMenu = (data) => async (dispatch, getState, api) => {
    try {
        let resp = await api.delete('MenuAdmin/DeleteMenu/'+data, {}, getState().auth.authToken);
        return dispatch({ type: ActionType.DELETEHELPSECTION, payload: resp });
    } catch (error) {
    let res = [{'status': 404, 'error': 'Error in deleting menu'}];
        return dispatch({ type: ActionType.DELETEHELPSECTION, payload: res });
    }
};
