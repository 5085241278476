import { ActionType } from "../model";

export const getContentList = () => async (dispatch, getState, api) => {
  	try {
  		let resp = await api.get('ContentAdmin/GetContentList?PageNo=1&PageSize=20', {}, getState().auth.authToken);
  		return dispatch({ type: ActionType.GETCONTENTLIST, payload: resp });
  	} catch (error) {
      let res = [{'status': 404, 'error': 'Error in getting banner list'}];
  		return dispatch({ type: ActionType.GETCONTENTLIST, payload: res });
  	}
};

export const getcontenttypeId = () => async (dispatch, getState, api) => {
	try {
		let resp = await api.get('contentTypesAdmin/GetContentTypeList', {}, getState().auth.authToken);
		return dispatch({ type: ActionType.GETCONTENTTYPES, payload: resp });
	} catch (error) {
	let res = [{'status': 404, 'error': 'Error in getting banner list'}];
		return dispatch({ type: ActionType.GETCONTENTTYPES, payload: res });
	}
};

export const addContentList = (data) => async (dispatch, getState, api) => {
	try {
		let resp = await api.post('ContentAdmin/AddContent', data, '', getState().auth.authToken);
		return dispatch({ type: ActionType.ADDCONTENTLIST, payload: resp });
	} catch (error) {
		let res = [{'status': 404, 'error': 'Error in getting adding content list'}];
		return dispatch({ type: ActionType.ADDCONTENTLIST, payload: res });
	}
};

export const deleteContentById = (recordId) => async (dispatch, getState, api) => {
	try {
	  let resp = await api.delete('ContentAdmin/DeleteContent/'+recordId, {}, '', getState().auth.authToken);
	  return dispatch({ type: ActionType.DELETECONTENT, payload: resp });
	} catch (error) {
	  let res = [{'status': 404, 'error': 'Error in deleting data'}];
	  return dispatch({ type: ActionType.DELETECONTENT, payload: res });
	}
}


export const getContentDetailById = (recordId) => async (dispatch, getState, api) => {
	try {
	  let resp = await api.get('ContentAdmin/GetContentDetail/'+recordId, {}, '', getState().auth.authToken);
	  return dispatch({ type: ActionType.GETCONTENTBYID, payload: resp });
	} catch (error) {
	  let res = [{'status': 404, 'error': 'Error in fetching data'}];
	  return dispatch({ type: ActionType.GETCONTENTBYID, payload: res });
	}
}

export const getProductsQuery = (query) => async (dispatch, getState, api) => {
	try {
	  let resp = await api.get('products/GetProductsListEditor?query='+ query, {}, '', getState().auth.authToken);
	  return dispatch({ type: ActionType.GETPRODUCTSEDITOR, payload: resp });
	} catch (error) {
	  let res = [{'status': 404, 'error': 'Error in fetching data'}];
	  return dispatch({ type: ActionType.GETPRODUCTSEDITOR, payload: res });
	}
}
  

export const updateContentList = (data) => async (dispatch, getState, api) => {
	try {
		let resp = await api.put('ContentAdmin/UpdateContent/'+data?.id, data, '', getState().auth.authToken);
		return dispatch({ type: ActionType.UPDATECONTENTID, payload: resp });
	} catch (error) {
		let res = [{'status': 404, 'error': 'Error in getting adding content list'}];
		return dispatch({ type: ActionType.UPDATECONTENTID, payload: res });
	}
};

export const searchProducts = (data) => async (dispatch, getState, api) => {
	try {
		let resp = await api.get('Products/GetProductsListEditor?query='+data, {}, getState().auth.authToken);
		return dispatch({ type: ActionType.SEARCHCONTENT, payload: resp });
	} catch (error) {
		let res = [{'status': 404, 'error': 'Error in getting adding content list'}];
		return dispatch({ type: ActionType.SEARCHCONTENT, payload: res });
	}
};

export const getallshops = (data) => async (dispatch, getState, api) => {
    try {
        let resp = await api.get('ShopsAdmin/GetShopsList', data, getState().auth.authToken);
        
        return dispatch({ type: ActionType.GETALLSHOPLIST, payload: resp });
    } catch (error) {
        console.log(error)
        let res = [{'status': 404, 'error': 'Error in getting Shop List'}];
        return dispatch({ type: ActionType.GETALLSHOPLIST, payload: res });
    }
};


export const searchContentList = (searchText) => async (dispatch, getState, api) => {
	try {
	  let resp = await api.get('ContentAdmin/SearchContentTitlesList?search='+searchText, {}, getState().auth.authToken);
	  return dispatch({ type: ActionType.SEARCHCONTENTLIST, payload: resp });
	} catch (error) {
	  let res = [{'status': 404, 'error': 'Error in getting list'}];
	  return dispatch({ type: ActionType.SEARCHCONTENTLIST, payload: res });
	}
}

export const addProductContentList = (data) => async (dispatch, getState, api) => {
	try {
	  let resp = await api.post('ContentAdmin/AddContentsToProduct', data, '', getState().auth.authToken);
	  return dispatch({ type: ActionType.ADDPRODUCTCONTENTLIST, payload: resp });
	} catch (error) {
	  let res = [{'status': 404, 'error': 'Error in getting list'}];
	  return dispatch({ type: ActionType.ADDPRODUCTCONTENTLIST, payload: res });
	}
}

export const getProductContentList = (data) => async (dispatch, getState, api) => {
	try {
	  let resp = await api.get('ContentAdmin/GetContentFromProduct?productID='+data, {}, getState().auth.authToken);
	  return dispatch({ type: ActionType.GETCONTENTPRODUCTLIST, payload: resp });
	} catch (error) {
	  let res = [{'status': 404, 'error': 'Error in getting list'}];
	  return dispatch({ type: ActionType.GETCONTENTPRODUCTLIST, payload: res });
	}
}

export const createToken = (id) => async (dispatch, getState, api) => {
	try {
	  let resp = await api.get('ContentAdmin/CreateToken?contentId='+id, {}, getState().auth.authToken);
	  return dispatch({ type: ActionType.GETACCESSTOKEN, payload: resp });
	} catch (error) {
	  let res = [{'status': 404, 'error': 'Error in getting token'}];
	  return dispatch({ type: ActionType.GETACCESSTOKEN, payload: res });
	}
}

export const editionsRelatedProductContentList = (data) => async (dispatch, getState, api) => {
	try {
	  let resp = await api.post('ProductsAdmin/AddRelatedProductEditions', data, '', getState().auth.authToken);
	  return dispatch({ type: ActionType.EDITIONSPRODUCTLIST, payload: resp });
	} catch (error) {
	  let res = [{'status': 404, 'error': 'Error in getting list'}];
	  return dispatch({ type: ActionType.EDITIONSPRODUCTLIST, payload: res });
	}
}

export const addRelatedProductContentList = (data) => async (dispatch, getState, api) => {
	try {
	  let resp = await api.post('ProductsAdmin/AddRelatedProducts', data, '', getState().auth.authToken);
	  return dispatch({ type: ActionType.RELATEDPRODUCTLIST, payload: resp });
	} catch (error) {
	  let res = [{'status': 404, 'error': 'Error in getting list'}];
	  return dispatch({ type: ActionType.RELATEDPRODUCTLIST, payload: res });
	}
}
export const getProductEditions = (data) => async (dispatch, getState, api) => {
	try {
	  let resp = await api.get('Products/GetProductEditions?productId='+data, {}, getState().auth.authToken);
	  return dispatch({ type: ActionType.GETPRODUCTEDITIONS, payload: resp });
	} catch (error) {
	  let res = [{'status': 404, 'error': 'Error in getting list'}];
	  return dispatch({ type: ActionType.GETPRODUCTEDITIONS, payload: res });
	}
}
export const GetRelatedProducts = (data) => async (dispatch, getState, api) => {
	try {
	  let resp = await api.get('Products/GetRelatedProducts?productId='+data, {}, getState().auth.authToken);
	  return dispatch({ type: ActionType.GETPRODUCTSRELATED, payload: resp });
	} catch (error) {
	  let res = [{'status': 404, 'error': 'Error in getting list'}];
	  return dispatch({ type: ActionType.GETPRODUCTSRELATED, payload: res });
	}
}
