import React from 'react';
import { AppContent, AppSidebar, AppFooter, AppHeader } from '../components/index';
import {Helmet} from "react-helmet";

const DefaultLayout = (props) => {
  const {children} = props;
  return (               
        <div className="wrapper d-flex flex-column min-vh-100 bg-light p-relative">       
            <Helmet>
                <meta charSet="utf-8" />
                <title>{process.env.REACT_APP_ENV != 'Production' ? process.env.REACT_APP_ENV  : ''}</title>          
            </Helmet>  
            <div className="body flex-grow-1 px-3">
                {children}
            </div>
            <AppFooter />
        </div>    
    )
}

export default DefaultLayout
