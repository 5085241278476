import React, { lazy } from 'react';
import { Switch, Redirect } from 'react-router-dom';
import RouteWithLayout from './RouteWithLayout'
import EmptyLayout  from '../layout/EmptyLayout';
import DefaultLayout  from '../layout/DefaultLayout';
import LayoutWithoutMargins from '../layout/LayoutWithoutMargins';

const PublicRoutes = () => {
    return (
        <Switch>
            <RouteWithLayout
                component={lazy(() => (import('../views/auth/login/Login')))}
                exact
                layout={EmptyLayout}
                path="/newadmin/signIn"
            />
           <RouteWithLayout
                component={lazy(() => (import('../views/banners')))}
                exact
                layout={DefaultLayout}
                path="/newadmin/banners"
            />
            <RouteWithLayout
                component={lazy(() => (import('../views/banners/addBanner')))}
                exact
                layout={DefaultLayout}
                path="/newadmin/banners/add"
            />
            <RouteWithLayout
                component={lazy(() => (import('../views/content')))}
                exact
                layout={DefaultLayout}
                path="/newadmin/content"
            />

            <RouteWithLayout
                component={lazy(() => (import('../views/content/addContent')))}
                exact
                layout={DefaultLayout}
                path="/newadmin/content/add"
            />

            <RouteWithLayout
                component={lazy(() => (import('../views/content/addContent')))}
                exact
                layout={DefaultLayout}
                path="/newadmin/content/:id"
            />
            <RouteWithLayout
                component={lazy(() => (import('../views/productAttributes')))}
                exact
                layout={DefaultLayout}
                path="/newadmin/productAttributes/:id"
            />
            <RouteWithLayout
                component={lazy(() => (import('../views/contentToProduct')))}
                exact
                layout={DefaultLayout}
                path="/newadmin/contentToProduct/:id"
            />
            <RouteWithLayout
                component={lazy(() => (import('../views/productToProduct')))}
                exact
                layout={DefaultLayout}
                path="/newadmin/productToProduct/:id"
            />
            <RouteWithLayout
                component={lazy(() => (import('../views/attributes')))}
                exact
                layout={DefaultLayout}
                path="/newadmin/attributes"
            />
            <RouteWithLayout
                component={lazy(() => (import('../views/categories')))}
                exact
                layout={DefaultLayout}
                path="/newadmin/categories"
            />
            <RouteWithLayout
                component={lazy(() => (import('../views/events')))}
                exact
                layout={DefaultLayout}
                path="/newadmin/events"
            />

            <RouteWithLayout
                component={lazy(() => (import('../views/coupons')))}
                exact
                layout={DefaultLayout}
                path="/newadmin/coupons"
            />
            <RouteWithLayout
                component={lazy(() => (import('../views/shops')))}
                exact
                layout={DefaultLayout}
                path="/newadmin/shops"
            />
            <RouteWithLayout
                component={lazy(() => (import('../views/ProductTaxes')))}
                exact
                layout={DefaultLayout}
                path="/newadmin/ProductTaxes"
            />
            <RouteWithLayout
                    component={lazy(() => (import('../views/shippingCompanies')))}
                    exact
                    layout={DefaultLayout}
                    path="/shippingCompanies"
            />
            <RouteWithLayout
                component={lazy(() => (import('../views/webTexts')))}
                exact
                layout={DefaultLayout}
                path="/webTexts"
            />
            <RouteWithLayout
                component={lazy(() => (import('../views/shippingCompanies')))}
                exact
                layout={DefaultLayout}
                path="/newadmin/shippingCompanies"
            />
            <RouteWithLayout
                component={lazy(() => (import('../views/webTexts')))}
                exact
                layout={DefaultLayout}
                path="/newadmin/webTexts"
            />
            <RouteWithLayout
                component={lazy(() => (import('../views/menu')))}
                exact
                layout={DefaultLayout}
                path="/newadmin/menu"
            />
            <RouteWithLayout
                component={lazy(() => (import('../views/webPage')))}
                exact
                layout={DefaultLayout}
                path="/newadmin/webPage"
            />
            <RouteWithLayout
                component={lazy(() => (import('../views/helpPage')))}
                exact
                layout={DefaultLayout}
                path="/newadmin/helpPage"
            />
            <RouteWithLayout
                component={lazy(() => (import('../views/subscriptionOrders')))}
                exact
                layout={DefaultLayout}
                path="/newadmin/subscriptionOrders"
            />
            <RouteWithLayout
                component={lazy(() => (import('../views/productCategories')))}
                exact
                layout={LayoutWithoutMargins}
                path="/newadmin/productCategories/:id"
            />
            <RouteWithLayout
                component={lazy(() => (import('../views/productTexts')))}
                exact
                layout={LayoutWithoutMargins}
                path="/newadmin/productTexts/:id"
            />
            <RouteWithLayout
                component={lazy(() => (import('../views/productMediaReviews')))}
                exact
                layout={LayoutWithoutMargins}
                path="/newadmin/productMediaReviews/:id"
            />
            <RouteWithLayout
                component={lazy(() => (import('../views/productPressMaterial')))}
                exact
                layout={LayoutWithoutMargins}
                path="/newadmin/productPressMaterial/:id"
            />
			<RouteWithLayout
                component={lazy(() => (import('../views/productsTransfers')))}
                exact
                layout={LayoutWithoutMargins}
                path="/newadmin/productsTransfers/:almacen"
            />        
			<RouteWithLayout
                component={lazy(() => (import('../views/transferOrdersReception')))}
                exact
                layout={LayoutWithoutMargins}
                path="/newadmin/transferOrdersReception/:almacen"
            />
			<RouteWithLayout
                component={lazy(() => (import('../views/transferOrdersReception')))}
                exact
                layout={LayoutWithoutMargins}
                path="/newadmin/transferOrdersReception"
            />
			<RouteWithLayout
                component={lazy(() => (import('../views/transferOrdersSending')))}
                exact
                layout={LayoutWithoutMargins}
                path="/newadmin/transferOrdersSending/:almacen"
            />
			<RouteWithLayout
                component={lazy(() => (import('../views/transferOrdersSending')))}
                exact
                layout={LayoutWithoutMargins}
                path="/newadmin/transferOrdersSending"
            />
		</Switch>
    )
};

export default PublicRoutes;
