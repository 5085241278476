import { useMemo } from "react";
import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import * as authAction from './auth';
import * as bannerAction from './banner';
import * as imageAction from './common';
import * as contentAction from './content';
import * as attributesAction from './attributes';
import * as categoryAction from './category';
import * as publishingLineAction from './publishingLine';
import * as collectionAction from './collection';
import * as eventAction from './event';
import * as couponAction from './coupons';
import * as shopAction from './shop';
import * as producttaxAction from './producttaxes';
import * as productAction from './product';
import * as shippingcompaniesAction from './shippingcompany';
import * as webtextAction from './webtext';
import * as webPageAction from './webpage';
import * as menuAction from './menu';
import * as helpAction from './helppage';
import * as subscriptionOrdersAction from './subscriptionOrders';
import * as storageAction from './storage';
import * as transferOrdersAction from './transferOrders';

export function useActions(actions){
	const dispatch = useDispatch();
	return useMemo(
		() => {
			if (Array.isArray(actions)) {
				return actions.map(a => bindActionCreators(a, dispatch));
			}
			return bindActionCreators(actions, dispatch);
		},
		[dispatch, actions]
	);
}

export {
	authAction,
	bannerAction,
	imageAction,
	contentAction,
	attributesAction,
	categoryAction,
	publishingLineAction,
	collectionAction,
	eventAction,
	couponAction,
	shopAction,
	producttaxAction,
	shippingcompaniesAction,
	webtextAction,
    menuAction,
    webPageAction,
	helpAction,
    subscriptionOrdersAction,
	productAction,
	storageAction,
	transferOrdersAction
}
